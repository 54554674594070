import React, { useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import Header from "../Layout/Header";
import Breadcrumb from "../Layout/Breadcrumb";
import { applicationBreadcrumbs } from "../../types/breadcrumbNavType";
import { APPLICATION, DOCUMENT } from "../../lib/api";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import FormSteps from "../FormStage/FormStage";

import { FileInput } from "./FileInput";
import { FileUploadModal } from "./FileUploadModal";
import { documentUploadSteps } from "../../types/formStepsPropsTypes";

const DocumentUpload: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [documentSuccess, setDocumentSuccess] = useState<boolean>(false);
  const [documentError, setDocumentError] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { id } = useParams();
  const [currentDocumentType, setCurrentDocumentType] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<{ [key: string]: string | null }>({});

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const handleFileSelect = useCallback((file: File) => {
    setSelectedFile(file);
  }, []);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${BACKEND_URL}/${APPLICATION.UPDATERAG}/${id}`,
        {},
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setDocumentSuccess(true)
      } else {
        setDocumentError('Failed to upload status')
      }
    } catch (error) {
      setDocumentError("Error while updating application");
    }
  };

  const handleUpload = useCallback(async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        const response = await axios.post(
          `${BACKEND_URL}${DOCUMENT.UPLOAD}/${id}/${currentDocumentType}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" }, 
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          console.log("File uploaded successfully!");
          setUploadedFiles((prev) => ({
            ...prev,
            [currentDocumentType]: selectedFile.name,
          }));
          setSuccess(true);
          setError("");
        } else {
          setError("Upload failed");
          setSuccess(false);
        }
      } catch (error) {
        setError("Error during file upload");
        setSuccess(false);
      }
    }
  }, [selectedFile, currentDocumentType]);

  const openModal = (documentType: keyof typeof documentTypes) => {
    setCurrentDocumentType(documentTypes[documentType]);
    setIsModalOpen(true);
    setSuccess(false);
    setError("");
  };

  const documentTypes = {
    Business_Requirement: "Business_Requirement",
    API_Documentation: "API_Documentation",
    Service_Level_Agreement: "Service_Level_Agreement",
    Release_Notes: "Release_Notes",
    System_Design: "System_Design",
    Test_Plan: "Test_Plan",
    User_Interface: "User_Interface",
    User_Manuals: "User_Manuals",
    Other_Document: "Other_Document"
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted"); 
  };

  return (
    <>
      <Header />
      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 mt-5 pb-20">
        <h2 className="text-2xl font-normal text-black mt-6">Upload Documents</h2>
        <p className="text-sm font-medium text-zinc-500 mt-4">
          <Breadcrumb items={applicationBreadcrumbs} />
        </p>
        <div className="mt-6 bg-white shadow-sm shadow-slate-400/40 flex rounded-lg mx-auto justify-center flex-col md:col-span-2">
          <FormSteps steps={documentUploadSteps} /> 
          <div className="mt-12 w-full justify-center flex flex-col p-5 md:p-0 mx-auto max-w-4xl">
            <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
              Documents
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              All documents related to this application
            </p>
            <form className="mt-8 max-w-4xl sm:mt-18" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 mt-3 gap-x-6 gap-y-6 sm:grid-cols-4">
                {Object.entries(documentTypes).map(([key, label]) => (
                  <FileInput
                    key={key}
                    id={key}
                    label={label.replace(/_/g, " ")}
                    onChooseFileClick={() => openModal(key as keyof typeof documentTypes)}
                    uploadedFileName={uploadedFiles[key] || null}
                  />
                ))}
              </div>
              <div className="flex items-center justify-end mt-9 mb-8 gap-x-3">
              <Link to="/dashboard">
                  <button
                    type="button"
                    className="text-sm w-32 h-10 font-semibold leading-6 text-gray-900"
                  >
                    Back 
                  </button>
                </Link>
                  <button
                    type="button"
                    onClick={handleSave}
                    className="rounded-md w-32 h-10 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm"
                  >
                    Save 
                  </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <FileUploadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleUpload}
        onFileSelect={handleFileSelect}
        documentType={currentDocumentType}
        success={success}
        error={error}
      />
        {documentSuccess && (
        <div className="fixed inset-0 z-10 bg-gray-300 bg-opacity-75 overscroll-y-none">
          <div className="flex justify-center items-center h-full mx-12 overscroll-none ">
            <div className="bg-white w-64 h-64 p-8 rounded-lg shadow-lg overscroll-none flex flex-col items-center">
              <CheckIcon className="text-green-500 w-20 h-10 stroke-green-500 stroke-[4] font-bold" />

              <h2 className="text-lg font-bold mb-4 mt-4">Updated</h2>
              <p className="text-gray-700 mb-5 text-center  ">
                Document Saved successfully.
              </p>
              <Link
                to="/dashboard"
                className="bg-blue-500 text-white text-center w-40 py-1 rounded-md hover:bg-blue-600"
              >
                Done
              </Link>
            </div>
          </div>
        </div>
      )}
      {documentError && (
        <div className="fixed inset-0 z-10 bg-gray-300 bg-opacity-75 overscroll-y-none">
          <div className="flex justify-center items-center h-full mx-12 overscroll-none ">
            <div className="bg-white w-64 h-64 p-8 rounded-lg shadow-lg overscroll-none flex flex-col items-center">
              <XMarkIcon className="text-red-500 w-20 h-10 stroke-red-500 stroke-[4] font-bold" />

              <h2 className="text-lg font-bold mb-4 mt-4">Error</h2>
              <p className="text-gray-700 mb-5 text-center  ">
                {error && (
                  <div className="text-gray-500 font-normal text-base ">
                    {error}
                  </div>
                )}
              </p>
              <button
                onClick={() => setDocumentError("")}
                className="bg-blue-500 text-white text-center w-40 py-1 rounded-md hover:bg-blue-600"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentUpload;
