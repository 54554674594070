import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";

interface Department {
  _id: string;
  name: string;
  applications: string[];
}

interface DepartmentCardProps {
  department: Department;
}

const DepartmentCard: React.FC<DepartmentCardProps> = ({ department }) => {
  return (
    <div className="flex flex-col shadow-sm h-32 rounded-lg shadow-slate-400/40">
      <div className="bg-white relative rounded-t-lg h-28 rounded-b-none py-3 px-5">
        <div className="flex  justify-between relative items-center ">
          <h3 className="font-medium text-base text-black">
            {department.name}
          </h3>
          <Link
            to={`/update-department/${department._id}`}
            className="lg:absolute lg:top-0.5 lg:left-44 2xl:left-48"
          >
            <PencilSquareIcon className="text-gray-500 w-5 h-5 font-bold" />
          </Link>
        </div>
        <p className="text-gray-500 absolute text-xs top-3/4">
          Total apps <span>{department.applications.length}</span>
        </p>
      </div>
      <div className="relative bg-gray-50 rounded-b-lg h-10 rounded-t-none py-1.5 px-5">
        <Link to={`/adding-application/${department._id}`}>
          <button
            type="button"
            className="text-xs text-indigo-500 cursor-pointer"
          >
            view all
          </button>
        </Link>
      </div>
    </div>
  );
};

export default DepartmentCard;
