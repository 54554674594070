type RagStatusProps = {
    name?: string;  
    status: 'Green' | 'Amber' | 'Red';  
    size: number;  
  };
  
const RagStatus: React.FC<RagStatusProps> = ({ name, status, size }) => {
    const sizeClass = `h-${size} w-${size}`;
  
    const statusColor = {
      Green: 'bg-green-600',
      Amber: 'bg-amber-600',
      Red: 'bg-red-600'
    };
  
    return (
      <div className="flex items-center">
        <div className={`${sizeClass} rounded-full ${statusColor[status]} mr-2`}></div>
      </div>
    );
  };
  
  export default RagStatus;