import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Layout/Header";
import DepartmentCard from "../../components/DashBoard/DashBoardCard";
import { SessionStorage } from "../../components/SessionStorage/SessionStorage";

interface Department {
  _id: string;
  name: string;
  applications: string[];
}

const DashBoardPage: React.FC = () => {
  const [departments, setDepartments] = useState<Department[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${BACKEND_URL}/department/get`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
        setDepartments(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <Header />
      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 2xl:pr-8 2xl:pl-11">
        <div className="flex justify-between items-center mt-5">
          <h1 className="text-black text-2xl font-normal">
            Hello, {SessionStorage.getName()}!{" "}
          </h1>
          <Link to={"/create-department"}>
            <button
              type="button"
              className="h-10 rounded py-2 px-8 gap-2 flex items-center bg-indigo-500 text-sm font-semibold text-white shadow-sm"
            >
              Create Dept
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00002 1.6665V12.3332M12.3334 6.99984L1.66669 6.99984"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </Link>
        </div>
        <div className="border-t bg-gray-300 mt-12"></div>

        {isLoading && <p>Loading departments...</p>}
        {error && <p>Error fetching departments: {error.message}</p>}

        {!isLoading && !error && departments.length > 0 && (
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8">
            {departments.map((department, index) => (
              <DepartmentCard key={index} department={department} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashBoardPage;
