import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ArchTechProps } from "../../types/stageComponentTypes";
import { ArchTechData } from "../../types/aplicationCreateTypes";
import { useAppForm } from "../../context/AppFormContext";
import Header from "../Layout/Header";
import FormSteps from "../FormStage/FormStage";
import Breadcrumb from "../Layout/Breadcrumb";
import { archTechSteps } from "../../types/formStepsPropsTypes";
import { applicationBreadcrumbs } from "../../types/breadcrumbNavType";

const validationSchema = Yup.object().shape({
  applicationType: Yup.string().required("Please select the option"),
});

const ArchitectureTechnology: React.FC<ArchTechProps> = ({
  onNext,
  onPrev,
}) => {
  const { state, dispatch } = useAppForm();

  const formik = useFormik<ArchTechData>({
    initialValues: {
      applicationType:
        (state.architectureAndTechnology as ArchTechData)?.applicationType ||
        "",
      technicalArchitecture:
        (state.architectureAndTechnology as ArchTechData)
          ?.technicalArchitecture || "",
      technologyStack:
        (state.architectureAndTechnology as ArchTechData)?.technologyStack ||
        [],
      featureEnhancement:
        (state.architectureAndTechnology as ArchTechData)?.featureEnhancement ||
        "",
      softwareTools:
        (state.architectureAndTechnology as ArchTechData)?.softwareTools || [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch({ type: "SET_ARCH_TECH", payload: values });
      onNext();
    },
  });

  return (
    <>
      <Header />
      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 mt-5 pb-20">
        <h2 className="text-2xl font-normal text-black mt-6">
          Add Application
        </h2>
        <p className="text-sm font-medium text-zinc-500 mt-4">
          {" "}
          <Breadcrumb items={applicationBreadcrumbs} />
        </p>
        <div className=" mt-6 bg-white shadow-sm shadow-slate-400/40 flex rounded-lg mx-auto justify-center flex-col md:col-span-2">
          <FormSteps steps={archTechSteps} />
          <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0 mx-auto max-w-2xl">
            <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
              Architecture and Technology{" "}
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              Description of application architecture and technology stack.{" "}
            </p>

            <form
              onSubmit={formik.handleSubmit}
              className="mt-8 max-w-2xl sm:mt-18"
            >
              <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="applicationType"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Type of application<span className="text-red-500"> *</span>
                  </label>
                  <div className="mt-1">
                    <select
                      name="applicationType"
                      value={formik.values.applicationType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="block w-full rounded-md  py-2.5 px-3 text-gray-900 shadow-sm  border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    >
                      <option value="" disabled hidden>
                        Select Type
                      </option>
                      <option value="Home Grown" className="text-gray-900">
                        Home Grown
                      </option>
                      <option
                        value="Package Application"
                        className="text-gray-900"
                      >
                        Package Application
                      </option>
                    </select>
                    {formik.touched.applicationType &&
                    formik.errors.applicationType ? (
                      <div className="absolute text-red-500 text-sm mt-1">
                        {formik.errors.applicationType}
                      </div>
                    ) : null}{" "}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-x-6 mt-8 gap-y-6 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="technicalArchitecture"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Business/ technical architecture
                  </label>

                  <div className="mt-1">
                    <textarea
                      id="technicalArchitecture"
                      name="technicalArchitecture"
                      value={formik.values.technicalArchitecture}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxLength={2000}
                      placeholder="Provide the business / logical architecture"
                      className="block w-full h-24 rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="technologyStack"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Technology Stack{" "}
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="technologyStack"
                      name="technologyStack"
                      value={formik.values.technologyStack.join(", ")}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "technologyStack",
                          e.target.value.split(", ")
                        )
                      }
                      onBlur={formik.handleBlur}
                      maxLength={2000}
                      placeholder="Enter technologies separated by commas"
                      className="block w-full h-24 rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="featureEnhancement"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Feature enhancement Process{" "}
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="featureEnhancement"
                      name="featureEnhancement"
                      value={formik.values.featureEnhancement}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxLength={2000}
                      placeholder="New feature enhancements handled in the application"
                      className="block w-full h-24 rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="softwareTools"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Software Tools{" "}
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="softwareTools"
                      name="softwareTools"
                      value={formik.values.softwareTools.join(", ")}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "softwareTools",
                          e.target.value.split(", ")
                        )
                      }
                      onBlur={formik.handleBlur}
                      maxLength={2000}
                      placeholder="Enter software tools separated by commas"
                      className="block w-full h-24 rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center pb-10 sm:pb-0 justify-end mt-9 mb-8 gap-x-3 flex-end">
                <button
                  type="button"
                  onClick={() => {
                    onPrev();
                  }}
                  className="text-sm w-32 h-10 font-semibold leading-6 text-gray-900"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="rounded-md w-32 h-10 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArchitectureTechnology;
