import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import { useParams } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../Layout/Breadcrumb";
import { GETDEPTID } from "../../lib/api";
import { departmentBreadcrumbs } from "../../types/breadcrumbNavType";

interface Department {
  _id: string;
  name: string;
  applications: string[];
}

interface ApplicationDetails {
  POC: string;
  description: string;
  POCContactNumber: string;
  name: string;
}

const DepartmentSearchResult: React.FC = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { id } = useParams();
  const [applicationDetails, setApplicationDetails] =
    useState<ApplicationDetails>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}${GETDEPTID.GETID}/${id}`,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        setApplicationDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 mt-7 pb-20">
        <h2 className="text-2xl font-normal text-black">Add Department</h2>
        <p className="text-sm font-medium text-zinc-500 mt-6">
          <Breadcrumb items={departmentBreadcrumbs} />
        </p>
        <div className=" mt-2.5 bg-white shadow-sm  shadow-slate-400/40 flex  rounded-lg mx-auto justify-center flex-col  md:col-span-2">
          <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0  mx-auto max-w-2xl">
            <h2 className="text-gray-900 font-bold text-lg">
              Department overview
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              Summary of department
            </p>
            <form className="mt-8 max-w-2xl pb-16  sm:mt-18">
              <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Name<span className="text-red-500"> *</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={applicationDetails?.name || ""}
                      disabled
                      className="block w-full rounded-md py-2 px-3 text-gray-400 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="POC"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Point of Contact<span className="text-red-500"> *</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="POC"
                      name="POC"
                      value={applicationDetails?.POC || ""}
                      disabled
                      className="block w-full rounded-md py-2 px-3 text-gray-400 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="POCContactNumber"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Contact Number{" "}
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="POCContactNumber"
                      name="POCContactNumber"
                      value={applicationDetails?.POCContactNumber || ""}
                      disabled
                      className="block w-full rounded-md py-2 px-3 text-gray-400 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Description
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      value={applicationDetails?.description || ""}
                      disabled
                      className="block w-full h-24 rounded-md py-2 px-3 text-gray-400 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentSearchResult;
