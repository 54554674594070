import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TeamSupportProps } from "../../types/stageComponentTypes";
import { TeamMember, TeamSupportData } from "../../types/aplicationCreateTypes";
import { useAppForm } from "../../context/AppFormContext";
import { useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import FormSteps from "../FormStage/FormStage";
import { applicationBreadcrumbs } from "../../types/breadcrumbNavType";
import Breadcrumb from "../Layout/Breadcrumb";
import { teamSupportSteps } from "../../types/formStepsPropsTypes";
const TeamSupport: React.FC<TeamSupportProps> = ({ onNext, onPrev }) => {
  const { state, dispatch } = useAppForm();
  const navigate = useNavigate();

  const formik = useFormik<TeamSupportData>({
    initialValues: {
      teamAndSupport: (state.teamAndSupport as TeamMember[])?.length
        ? (state.teamAndSupport as TeamMember[]).map((member: TeamMember) => ({
            name: member.name || "",
            skills: member.skills || [],
          }))
        : [{ name: "", skills: [] }],
    },

    validationSchema: Yup.object().shape({
      teamAndSupport: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().matches(
            /^[a-zA-Z\s]+$/,
            "Name can only contain alphabets"
          ),
          skills: Yup.array().of(Yup.string()),
        })
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch({ type: "SET_TEAM_SUPPORT", payload: values });
      onNext();
    },
  });

  const handleAddMember = () => {
    const newMember = { name: "", skills: [] };
    formik.setFieldValue("teamAndSupport", [
      ...formik.values.teamAndSupport,
      newMember,
    ]);
  };

  return (
    <>
      <Header />
      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 mt-5 pb-20">
        <h2 className="text-2xl font-normal text-black mt-6">
          Add Application
        </h2>
        <p className="text-sm font-medium text-zinc-500 mt-4">
          {" "}
          <Breadcrumb items={applicationBreadcrumbs} />
        </p>{" "}
        <div className="mt-6 bg-white shadow-sm shadow-slate-400/40 flex rounded-lg mx-auto justify-center flex-col md:col-span-2">
          <FormSteps steps={teamSupportSteps} />
          <div className="mt-12 w-full justify-center flex flex-col p-5 md:p-0 mx-auto max-w-2xl">
            <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
              Team and Support{" "}
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              Information about the responsible team and support{" "}
            </p>

            <form
              onSubmit={formik.handleSubmit}
              className="mt-8 max-w-2xl sm:mt-18"
            >
              {formik.values.teamAndSupport.map((member, index) => (
                <div
                  key={index}
                  className="grid grid-cols-1 mt-3 gap-x-6 gap-y-6 sm:grid-cols-3"
                >
                  <div className="sm:col-span-1">
                    {index === 0 && (
                      <label
                        htmlFor={`name-${index}`}
                        className="block text-sm font-medium leading-6 text-gray-700"
                      >
                        Team member name
                      </label>
                    )}
                    <div className="mt-1">
                      <input
                        type="text"
                        id={`name-${index}`}
                        name={`teamAndSupport.${index}.name`}
                        value={formik.values.teamAndSupport[index]?.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Name"
                        className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    {index === 0 && (
                      <label
                        htmlFor={`skills-${index}`}
                        className="block text-sm font-medium leading-6 text-gray-700"
                      >
                        Skills
                      </label>
                    )}
                    <div className="mt-1">
                      <input
                        type="text"
                        id={`skills-${index}`}
                        name={`teamAndSupport.${index}.skills`}
                        value={formik.values.teamAndSupport[index]?.skills}
                        onChange={(e) => {
                          const newValue = e.target.value.split(",");
                          formik.setFieldValue(
                            `teamAndSupport.${index}.skills`,
                            newValue
                          );
                        }}
                        onBlur={formik.handleBlur}
                        placeholder="Skills"
                        className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddMember}
                className="rounded-sm bg-white px-3 py-2 text-sm font-semibold w-24 text-indigo-500 border border-indigo-500 mt-10"
              >
                Add
              </button>

              <div className="flex items-center pb-10 sm:pb-0 justify-end mt-9 mb-8 gap-x-3 flex-end">
                <button
                  type="button"
                  onClick={() => onPrev()}
                  className="text-sm w-32 h-10 font-semibold leading-6 text-gray-900"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="rounded-md w-32 h-10 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm"
                >
                  Preview
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamSupport;
