import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { DEPARTMENT } from "../../lib/api";
import { Link } from "react-router-dom";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

interface FormValues {
  id: string;
  name: string;
  POC: string;
  POCContactNumber: string;
  description: string;
}

interface UpdateDepartmentCardProps {
  applicationDetails: any;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(/^[a-zA-Z\s.]+$/, "Name can only contain alphabets"),
  POC: Yup.string()
    .required("POC Name is required")
    .matches(/^[a-zA-Z\s.]+$/, "Name can only contain alphabets"),
  POCContactNumber: Yup.string()
    .matches(/^[0-9]*$/, "Only numbers are allowed")
    .max(10, "Maximum of 10 digits are allowed"),
});

const UpdateDepartmentForm: React.FC<UpdateDepartmentCardProps> = (props) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { applicationDetails } = props;

  const formik = useFormik<FormValues>({
    initialValues: {
      name: applicationDetails?.name,
      POC: applicationDetails?.POC,
      POCContactNumber: applicationDetails?.POCContactNumber,
      description: applicationDetails?.description,
      id: applicationDetails?.id,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axios.put(
          `${BACKEND_URL}${DEPARTMENT.UPDATE}/${applicationDetails?._id}`,
          values,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        if (response.status === 200 || response.status === 201) {
          setSuccess(true);
          resetForm();
        }
      } catch (error) {
        console.error("API Error:", error);
        let errorMessage = "An unexpected error occurred";
        if (axios.isAxiosError(error)) {
          if (error.response) {
            errorMessage =
              error.response.data.message ||
              "An error occurred while creating the department";
          } else {
            errorMessage = "An error occurred while processing your request";
          }
        }
        setError(errorMessage);
      }
    },
  });
  return (
    <>
      <div
        className={`${
          success
            ? "absolute blur-sm mt-40 bg-opacity-75 overscroll-y-none max-h-max bg-gray-300 inset-0 z-10"
            : ""
        } `}
      ></div>
      <form onSubmit={formik.handleSubmit} className="mt-8 max-w-2xl  sm:mt-18">
        <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-700"
            >
              Name<span className="text-red-500"> *</span>
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Name of the department"
                className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
              />
              {formik.errors.name ? (
                <div className="absolute text-red-500 text-sm mt-1">
                  {formik.errors.name}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 mt-8 gap-x-6 gap-y-9 sm:grid-cols-2">
          <div>
            <label
              htmlFor="POC"
              className="block text-sm font-medium leading-6 text-gray-700"
            >
              Point of Contact<span className="text-red-500"> *</span>
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="POC"
                name="POC"
                value={formik.values.POC}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Name of Point of Contact"
                className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
              />
              {formik.errors.POC ? (
                <div className="absolute text-red-500 text-sm mt-1">
                  {formik.errors.POC}
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <label
              htmlFor="POCContactNumber"
              className="block text-sm font-medium leading-6 text-gray-700"
            >
              Contact Number{" "}
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="POCContactNumber"
                name="POCContactNumber"
                value={formik.values.POCContactNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Contact Number"
                maxLength={10}
                className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
              />
              {formik.errors.POCContactNumber ? (
                <div className="absolute text-red-500 text-sm mt-1">
                  {formik.errors.POCContactNumber}
                </div>
              ) : null}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="description"
              className="block text-sm font-medium leading-6 text-gray-700"
            >
              Description
            </label>
            <div className="mt-1">
              <textarea
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={2000}
                placeholder="Brief description of department"
                className="block w-full h-24 rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex items-center pb-10 sm:pb-0 justify-end mt-9 mb-8 gap-x-3 flex-end">
          <Link to="/dashboard">
            <button
              type="button"
              className="text-sm w-32 h-10 font-semibold leading-6 text-gray-900"
            >
              Back
            </button>
          </Link>
          <button
            type="submit"
            className=" w-32 h-10 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm"
          >
            Save
          </button>
        </div>
      </form>
      {success && (
        <div className="fixed inset-0 z-10 bg-gray-300 bg-opacity-75 overscroll-y-none">
          <div className="flex justify-center items-center h-full mx-12 overscroll-none ">
            <div className="bg-white w-64 h-64 p-8 rounded-lg shadow-lg overscroll-none flex flex-col items-center">
              <CheckIcon className="text-green-500 w-20 h-10 stroke-green-500 stroke-[4] font-bold" />

              <h2 className="text-lg font-bold mb-4 mt-4">Updated</h2>
              <p className="text-gray-700 mb-5 text-center  ">
                Department updated successfully.
              </p>
              <Link
                to="/dashboard"
                className="bg-blue-500 text-white text-center w-40 py-1 rounded-md hover:bg-blue-600"
              >
                Done
              </Link>
            </div>
          </div>
        </div>
      )}

      {error && (
        <div className="fixed inset-0 z-10 bg-gray-300 bg-opacity-75 overscroll-y-none">
          <div className="flex justify-center items-center h-full mx-12 overscroll-none ">
            <div className="bg-white w-64 h-64 p-8 rounded-lg shadow-lg overscroll-none flex flex-col items-center">
              <XMarkIcon className="text-red-500 w-20 h-10 stroke-red-500 stroke-[4] font-bold" />
              <h2 className="text-lg font-bold mb-4 mt-4">Error</h2>
              <div className="text-gray-700 mb-5 custom-scrollbar text-center max-h-32 overflow-auto">
                {error && (
                  <div className="text-gray-500  font-normal text-sm  word-break break-all">
                    {error}
                  </div>
                )}
              </div>
              <button
                onClick={() => setError("")}
                className="bg-blue-500 text-white text-center w-40 py-1 rounded-md hover:bg-blue-600"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateDepartmentForm;
