

export const overviewSteps = [
   {
      id: "01",
      name: "Overview",
      status: "current",
    },
    {
      id: "02",
      name: "Arch & Tech",

      status: "upcoming",
    },
    {
      id: "03",
      name: "Dev & Maintenance",

      status: "upcoming",
    },
    {
      id: "04",
      name: "Team & Support",

      status: "upcoming",
    },
    {
      id: "05",
      name: "Document Upload",

      status: "upcoming",
    },
    

];

export const archTechSteps = [
  {
      id: "01",
      name: "Overview",
      status: "complete",
    },
    {
      id: "02",
      name: "Arch & Tech",

      status: "current",
    },
    {
      id: "03",
      name: "Dev & Maintenance",
      status: "upcoming",
    },
    {
      id: "04",
      name: "Team & Support",
      status: "upcoming",
    },
    {
      id: "05",
      name: "Document Upload",

      status: "upcoming",
    },
    
];

export const devMaintenanceSteps = [
    {
      id: "01",
      name: "Overview",
      status: "complete",
    },
    {
      id: "02",
      name: "Arch & Tech",

      status: "complete",
    },
    {
      id: "03",
      name: "Dev & Maintenance",

      status: "current",
    },
    {
      id: "04",
      name: "Team & Support",

      status: "upcoming",
    },
    {
      id: "05",
      name: "Document Upload",

      status: "upcoming",
    }
  
];


export const teamSupportSteps = [
  {
    id: "01",
    name: "Overview",
    status: "complete",
  },
  {
    id: "02",
    name: "Arch & Tech",
    status: "complete",
  },
  {
    id: "03",
    name: "Dev & Maintenance",
    status: "complete",
  },
  {
    id: "04",
    name: "Team & Support",
    status: "current",
  },
  {
    id: "05",
    name: "Document Upload",

    status: "upcoming",
  }
  
];



export const documentUploadSteps = [
  {
    id: "01",
    name: "Overview",
    status: "complete",
  },
  {
    id: "02",
    name: "Arch & Tech",
    status: "complete",
  },
  {
    id: "03",
    name: "Dev & Maintenance",
    status: "complete",
  },
  {
    id: "04",
    name: "Team & Support",
    status: "complete",
  },
  {
    id: "05",
    name: "Document Upload",
    status: "current",
  }
  
];
