import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface FileUploadModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
    onFileSelect: (file: File) => void;
    documentType: string;
    success: boolean;
    error: string;
  }
  
  
  
  export const FileUploadModal: React.FC<FileUploadModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
    onFileSelect,
    documentType,
    success,
    error,
  }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
  
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
  
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full w-full max-w-md mx-4">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Upload File for {documentType && documentType.replace('_',' ')}
                  </h3>
                  <div className="mt-2">
                    <input
                      className="block w-full text-sm border h-9 file:h-9 file:text-sm text-gray-500 bg-white border-gray-300 file:bg-white file:border-0 file:border-e file:text-gray-500 file:border-e-gray-500 file:border-opacity-30 rounded-md cursor-pointer"
                      type="file"
                      onChange={(e) => {
                        if (e.target.files) {
                          onFileSelect(e.target.files[0]);
                        }
                      }}
                    />
                  </div>
                  {success && (
                    <div className="mt-2 text-green-600">
                      <CheckIcon className="w-5 h-5 inline" /> Document uploaded successfully.
                    </div>
                  )}
                  {error && (
                    <div className="mt-2 text-red-600">
                      <XMarkIcon className="w-5 h-5 inline" /> {error}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              {!success && !error && (
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onSubmit}
                >
                  Submit
                </button>
              )}
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={onClose}
              >
                {success || error ? 'Close' : 'Cancel'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };