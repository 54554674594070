import React, { useState, FC, ReactElement, useEffect } from "react";
import ArchitectureTechnology from "../../components/ArchitectureTechnology/ArchitectureTechnology";
import DevelopmentMaintenance from "../../components/DevelopmentMaintenance/DevelopmentMaintenance";
import TeamSupport from "../../components/TeamSupport/TeamSupport";
import { StageInfo } from "../../types/stageComponentTypes";
import { StageComponentType } from "../../types/stageComponentTypes";
import { useAppForm } from "../../context/AppFormContext";
import { useLocation } from "react-router-dom";
import ApplicationPreview from "../../components/ApplicationPreview/ApplicationPreview";
import ApplicationOverview from "../../components/ApplicationOverview/ApplicationOverview";
import DocumentUpload from "../../components/DocumentUpload/DocumentUpload";

const stages: Record<string, StageInfo> = {
  overview: {
    component: ApplicationOverview as StageComponentType,
    next: "archTech",
    prev: null,
  },
  archTech: {
    component: ArchitectureTechnology as StageComponentType,
    next: "devMaintenance",
    prev: "overview",
  },
  devMaintenance: {
    component: DevelopmentMaintenance as StageComponentType,
    next: "teamSupport",
    prev: "archTech",
  },
  teamSupport: {
    component: TeamSupport as StageComponentType,
    next: "appPreview",
    prev: "devMaintenance",
  },
  appPreview: {
    component: ApplicationPreview as StageComponentType,
    next: null,
    prev: "teamSupport",
  },
  documentUpload: {
    component: DocumentUpload as StageComponentType,
    next: null,
    prev: null,
  },
};

const AddApplication: FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { state, dispatch } = useAppForm();
  let department = query.get("departmentId");
  let departmentName = query.get("departmentName");
  useEffect(() => {
    dispatch({ type: "SET_DEPARTMENTID", payload: department! });
  }, [department]);
  useEffect(() => {
    dispatch({
      type: "SET_DEPARTMENTNAME",
      payload: departmentName!,
    });
  }, [departmentName]);
  const initialState: keyof typeof stages = "overview";
  const [currentStage, setCurrentStage] = useState(initialState);

  const handleNext = () => {
    const nextStage = stages[currentStage].next;
    if (nextStage) {
      setCurrentStage(nextStage);
    }
  };

  const handlePrev = () => {
    const prevStage = stages[currentStage].prev;
    if (prevStage) {
      setCurrentStage(prevStage);
    }
  };

  const CurrentComponent = stages[currentStage].component;

  return <CurrentComponent onNext={handleNext} onPrev={handlePrev} />;
};

export default AddApplication;
