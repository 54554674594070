import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { debounce } from "lodash";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

interface DepartmentSearchResult {
  _id: string;
  name: string;
}
interface ApplicationSearchResult {
  _id: string;
  overview: {
    appName: string;
  };
}

const SearchBar: React.FC = () => {
  const [query, setQuery] = useState<string>("");
  const [departmentSearchResults, setDepartmentSearchResults] = useState<
    DepartmentSearchResult[]
  >([]);
  const [applicationSearchResults, setApplicationSearchResults] = useState<
    ApplicationSearchResult[]
  >([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const debouncedSearch = debounce((query: string) => {
    setDebouncedQuery(query);
  }, 1000);

  useEffect(() => {
    if (debouncedQuery !== "") {
      fetchSearchResults(debouncedQuery);
    }
  }, [debouncedQuery]);

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    debouncedSearch(inputValue);

    setIsDropdownOpen(inputValue.trim().length > 0);
  };
  const fetchSearchResults = async (query: string) => {
    try {
      const response = await axios.get(
        `${BACKEND_URL}/search?searchTerm=${query}`,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setDepartmentSearchResults(response.data.data.departments);
      setApplicationSearchResults(response.data.data.applications);
    } catch (error) {
      console.error("Search API error:", error);
      return [];
    }
  };

  const handleSelectResult = (resultId: string) => {
    const selectedDepartmentResult = departmentSearchResults.find(
      (results) => results._id === resultId
    );
    const selectedApplicationResult = applicationSearchResults.find(
      (result) => result._id === resultId
    );
    if (selectedDepartmentResult) {
      setQuery(selectedDepartmentResult.name);
    } else if (selectedApplicationResult) {
      setQuery(selectedApplicationResult.overview.appName);
    }
    setIsDropdownOpen(false);
  };

  return (
    <div className="relative">
      <label htmlFor="search" className="sr-only text-gray-500">
        Search
      </label>
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.66913 4C7.60826 4 6.59085 4.42143 5.8407 5.17158C5.09056 5.92172 4.66913 6.93914 4.66913 8C4.66913 9.06087 5.09056 10.0783 5.8407 10.8284C6.59085 11.5786 7.60826 12 8.66913 12C9.72999 12 10.7474 11.5786 11.4976 10.8284C12.2477 10.0783 12.6691 9.06087 12.6691 8C12.6691 6.93914 12.2477 5.92172 11.4976 5.17158C10.7474 4.42143 9.72999 4 8.66913 4ZM2.66913 8C2.66901 7.05571 2.89177 6.12472 3.3193 5.28274C3.74683 4.44077 4.36705 3.7116 5.12952 3.15453C5.892 2.59746 6.77519 2.22822 7.70727 2.07684C8.63936 1.92546 9.59401 1.99621 10.4936 2.28335C11.3932 2.57049 12.2123 3.06591 12.8843 3.7293C13.5563 4.39269 14.0623 5.20534 14.361 6.10114C14.6597 6.99693 14.7428 7.9506 14.6035 8.88456C14.4641 9.81852 14.1063 10.7064 13.5591 11.476L18.3761 16.293C18.5583 16.4816 18.6591 16.7342 18.6568 16.9964C18.6545 17.2586 18.5494 17.5094 18.3639 17.6948C18.1785 17.8802 17.9277 17.9854 17.6655 17.9877C17.4033 17.99 17.1507 17.8892 16.9621 17.707L12.1461 12.891C11.2485 13.5293 10.1925 13.9082 9.0938 13.9861C7.99513 14.0641 6.8962 13.8381 5.91743 13.333C4.93866 12.8278 4.11782 12.063 3.54485 11.1224C2.97189 10.1817 2.66891 9.10144 2.66913 8Z"
            fill="#6B7280"
          />
        </svg>
      </div>
      <input
        id="search"
        name="search"
        className="block rounded-lg border w-48 sm:w-80 md:w-[28rem] lg:w-[34.5rem] border-gray-200 bg-gray-50 py-1.5 pl-10 pr-3 text-gray-900 placeholder:text-gray-500 placeholder:text-base placeholder:font-normal sm:text-sm sm:leading-6 appearance-none outline-none"
        placeholder="Search by department name or application name"
        type="search"
        value={query}
        onChange={handleSearch}
      />
      {isDropdownOpen && (
        <div className="absolute mt-1 w-full bg-white border border-gray-200 rounded-md shadow-md">
          <ul className="max-h-52 overflow-y-auto">
            {departmentSearchResults.slice(0, 4).map((results) => (
              <Link
                to={`/updateSearch-department/${results._id}`}
                key={results._id}
              >
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSelectResult(results._id)}
                >
                  {results.name}
                </li>
              </Link>
            ))}

            {applicationSearchResults.slice(0, 4).map((result) => (
              <Link to={`/view-application/${result._id}`} key={result._id}>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSelectResult(result._id)}
                >
                  {result.overview.appName}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
