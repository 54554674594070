import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppReviewProps } from "../../types/stageComponentTypes";
import { useAppForm } from "../../context/AppFormContext";
import { CheckIcon } from "@heroicons/react/24/solid";

import {
  ArchTechData,
  DevMaintenanceData,
  OverviewData,
  TeamMember,
  TeamSupportData,
} from "../../types/aplicationCreateTypes";
import axios from "axios";
import Header from "../Layout/Header";
import Breadcrumb from "../Layout/Breadcrumb";
import { applicationBreadcrumbs } from "../../types/breadcrumbNavType";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ApplicationPreview: React.FC<AppReviewProps> = ({ onPrev, onNext }) => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [success, setSuccess] = useState<boolean>(false);
  const [createdApp , setCreatedApp] = useState<string>('')
  const [error, setError] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { state, dispatch } = useAppForm();
  const navigate = useNavigate();

  const formatDate = (date: Date | string): string => {
    if (date instanceof Date) {
      return date.toLocaleDateString("en-US");
    }
    return date;
  };
  const handleApplicationSubmit = async () => {
    if (submitting) return;
    setSubmitting(true);
    try {
      const { departmentName, ...stateWithoutDeptName } = state;

      const response = await axios.post(
        `${BACKEND_URL}/application/create`,
        stateWithoutDeptName,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      if (response.status === 201 || response.status === 200) {
        setCreatedApp(response.data.data._id)
        setSuccess(true);
        onNext()
      }
    } catch (error) {
      let errorMessage = "An unexpected error occurred";
      if (axios.isAxiosError(error)) {
        if (error.response) {
          errorMessage =
            error.response.data.message ||
            "An error occurred while creating the application";
        } else {
          errorMessage = "An error occurred while processing your request";
        }
      }
      setError(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };
  const {
    overview,
    architectureAndTechnology,
    developmentAndMaintenance,
    teamAndSupport,
    isActive,
    department,
    departmentName,
  } = state;

  const safeJoin = (items: string[] | undefined) =>
    items ? items.join(", ") : "Not available";
  return (
    <>
      <div
        className={`${
          success
            ? "absolute blur-sm mt-40 bg-opacity-75 overscroll-y-none max-h-max bg-gray-300 inset-0 z-10"
            : ""
        } `}
      ></div>
      <Header />
      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 mt-5 pb-20">
        <h2 className="text-2xl font-normal text-black mt-6">
          Add Application
        </h2>
        <p className="text-sm font-medium text-zinc-500 mt-4">
          <Breadcrumb items={applicationBreadcrumbs} />
        </p>
        <div className=" mt-6 bg-white shadow-sm  shadow-slate-400/40 flex  rounded-lg mx-auto justify-center flex-col  md:col-span-2">
          <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0   mx-auto max-w-5xl">
            <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
              Application Overview
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              Summary of application details and usage{" "}
            </p>

            {overview && (
              <div className="mt-8 sm:mt-18">
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Name
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {(overview as OverviewData)?.appName}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Department
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {departmentName && departmentName}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 mt-8 max-w-2xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Owner
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {(overview as OverviewData)?.appOwner}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Source code link
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {(overview as OverviewData)?.sourceCodeLink}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Version
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {(overview as OverviewData)?.releaseVersion}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Version date
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {(overview as OverviewData)?.releaseDate
                          ? formatDate(
                              new Date((overview as OverviewData)?.releaseDate)
                            )
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-8 max-w-3xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Description{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify text-gray-700 text-sm sm:leading-6 ">
                        {(overview as OverviewData).description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-8 max-w-3xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Usage{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify text-gray-700 text-sm sm:leading-6 ">
                        {(overview as OverviewData).usage}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-8 max-w-3xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Key Features
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify   text-gray-700 text-sm sm:leading-6 ">
                        {safeJoin((overview as OverviewData)?.features)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6  max-w-2xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      End users{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {safeJoin((overview as OverviewData)?.endUsers)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="border border-t border-gray-300  max-w-5xl  mt-8"></div>
          </div>
          <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0   mx-auto max-w-5xl">
            <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
              Architecture and Technology
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              Details on application versioning, management, and maintenance.{" "}
            </p>

            {architectureAndTechnology && (
              <div className="mt-8 sm:mt-18">
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Type of application{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {
                          (architectureAndTechnology as ArchTechData)
                            ?.applicationType
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-8 max-w-3xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Business/ technical architecture{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {
                          (architectureAndTechnology as ArchTechData)
                            ?.technicalArchitecture
                        }
                      </p>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 mt-8 max-w-3xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Technology stack{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify text-gray-700 text-sm sm:leading-6 ">
                        {safeJoin(
                          (architectureAndTechnology as ArchTechData)
                            ?.technologyStack
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-8 max-w-3xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Feature enhancement Process{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {
                          (architectureAndTechnology as ArchTechData)
                            ?.featureEnhancement
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6  max-w-3xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Software Tools{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify   text-gray-700 text-sm sm:leading-6 ">
                        {safeJoin(
                          (architectureAndTechnology as ArchTechData)
                            ?.softwareTools
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="border border-t border-gray-300  max-w-5xl mt-8"></div>
          </div>
          <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0   mx-auto max-w-5xl">
            <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
              Development and Maintenance{" "}
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              Details on application versioning, management, and maintenance.{" "}
            </p>

            {developmentAndMaintenance && (
              <div className="mt-8 sm:mt-18">
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Managed by
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {
                          (developmentAndMaintenance as DevMaintenanceData)
                            ?.managedBy
                        }
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Ticket dump{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {(developmentAndMaintenance as DevMaintenanceData)
                          ?.ticketDump
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Name
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {
                          (developmentAndMaintenance as DevMaintenanceData)
                            ?.managerName
                        }
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Contact number{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {
                          (developmentAndMaintenance as DevMaintenanceData)
                            ?.managerContactNumber
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 mt-8 max-w-2xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Software Methodologies
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {
                          (developmentAndMaintenance as DevMaintenanceData)
                            ?.softwareMethodology
                        }
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Stability
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {(developmentAndMaintenance as DevMaintenanceData)
                          ?.stability
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Source code management{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {
                          (developmentAndMaintenance as DevMaintenanceData)
                            ?.sourceCodeManager
                        }
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Code stored in{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {
                          (developmentAndMaintenance as DevMaintenanceData)
                            ?.codeStorage
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6  max-w-3xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Connectivity planning{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {!!(developmentAndMaintenance as DevMaintenanceData)
                          ?.connectivityPlanning
                          ? (developmentAndMaintenance as DevMaintenanceData)
                              ?.connectivityPlanning
                          : "NA"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6  max-w-3xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Release Management and Change Control{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify text-gray-700 text-sm sm:leading-6 ">
                        {!!(developmentAndMaintenance as DevMaintenanceData)
                          ?.releaseAndChange
                          ? (developmentAndMaintenance as DevMaintenanceData)
                              ?.releaseAndChange
                          : "NA"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="border border-t border-gray-300  max-w-5xl  mt-8"></div>
          </div>
          <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0   mx-auto max-w-5xl">
            <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
              Team and Support{" "}
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              Information about the responsible team and support{" "}
            </p>

            <div className="mt-8 sm:mt-18">
              {Array.isArray(teamAndSupport) &&
                teamAndSupport.map((member: TeamMember, index: number) => (
                  <div
                    key={index}
                    className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl mt-8"
                  >
                    <div>
                      <h4 className="text-sm font-medium leading-6 text-gray-700">
                        Name
                      </h4>
                      <div className="mt-1">
                        <p className="w-full text-gray-700 text-sm sm:leading-6">
                          {member?.name}
                        </p>
                      </div>
                    </div>
                    <div>
                      <h4 className="text-sm font-medium leading-6 text-gray-700">
                        Skills
                      </h4>
                      <div className="mt-1">
                        <p className="w-full text-gray-700 text-sm sm:leading-6">
                          {member?.skills.join(", ")}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="border border-t border-gray-300  max-w-5xl  mt-8"></div>
          </div>
          <div className="flex items-center w-full pb-10 sm:pb-0 justify-end mt-9 mb-8 mx-auto  gap-x-3 max-w-5xl flex-end">
            <button
              type="button"
              onClick={() => {
                onPrev();
              }}
              className="text-sm w-32 h-10 font-semibold leading-6 text-gray-900"
            >
              Update
            </button>

            <button
              type="submit"
              onClick={handleApplicationSubmit}
              className="rounded-md w-32 h-10 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm"
            >
              Create App
            </button>
          </div>
        </div>
      </div>
      {success && (
        <div className="fixed inset-0 z-10 bg-gray-300 bg-opacity-75 overscroll-y-none">
          <div className="flex justify-center items-center h-full mx-12 overscroll-none ">
            <div className="bg-white w-64 h-64 p-8 rounded-lg shadow-lg overscroll-none flex flex-col items-center">
              <CheckIcon className="text-green-500 w-20 h-10 stroke-green-500 stroke-[4] font-bold" />

              <h2 className="text-lg font-bold mb-1 mt-4">Added</h2>
              <p className="text-gray-700 mb-3 text-center  ">
                Application added successfully, please add documents.
              </p>
              <Link
                to={`/document-upload/${createdApp}`}
                className="bg-blue-500 text-white text-center w-40 py-1 rounded-md hover:bg-blue-600"
              >
                Done
              </Link>
            </div>
          </div>
        </div>
      )}
      {error && (
        <div className="fixed inset-0 z-10 bg-gray-300 bg-opacity-75 overscroll-y-none">
          <div className="flex justify-center items-center h-full mx-12 overscroll-none ">
            <div className="bg-white w-64 h-64 p-8 rounded-lg shadow-lg overscroll-none flex flex-col items-center">
              <XMarkIcon className="text-red-500 w-20 h-10 stroke-red-500 stroke-[4] font-bold" />
              <h2 className="text-lg font-bold mb-4 mt-4">Error</h2>
              <div className="text-gray-700 mb-5 custom-scrollbar text-center max-h-40 overflow-auto">
                {error && (
                  <div className="text-gray-500  font-normal text-sm  word-break break-all">
                    {error}
                  </div>
                )}
              </div>
              <button
                onClick={() => setError("")}
                className="bg-blue-500 text-white text-center w-40 py-1 rounded-md hover:bg-blue-600"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicationPreview;
