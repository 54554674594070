import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import HeaderLogo from "../../assets/topLogo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AUTH } from "../../lib/api";
import SearchBar from "./Searchbar";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

interface NavigationItem {
  name: string;
  href: string;
  current: boolean;
}

const navigation: NavigationItem[] = [
  { name: "Log Out", href: "/", current: true },
];

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

const Header: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${BACKEND_URL}${AUTH.LOGOUT}`);
      if (response.status === 200 || response.status === 201) {
        sessionStorage.removeItem("name");
        sessionStorage.removeItem("roles");
        navigate("/login");
      } else {
        console.error("Logout error:", response.data);
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  return (
    <div className="top-0 z-50 sticky">
      <Popover
        as="header"
        className={({ open }: { open: boolean }) =>
          classNames(
            open ? "fixed inset-0 z-40 overflow-y-auto" : "",
            "bg-white shadow-sm lg:static lg:overflow-y-visible"
          )
        }
      >
        {({ open }: { open: boolean }) => (
          <div className="shadow-md shadow-black/5 ">
            <div className="px-4 mx-auto max-w-[90rem] sm:px-6 py-2.5 lg:px-8 lg:py-3 xl:px-10 xl:py-3 2xl:pr-8 2xl:py-3 2xl:pl-11">
              <div className="relative flex justify-between items-center xl:gap-12 xl:grid xl:grid-cols-12">
                <div className="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
                  <div className="flex items-center flex-shrink-0 space-x-5">
                    <h1 className="text-headerLogoColor font-medium text-2xl">
                      BBMP Report
                    </h1>
                  </div>
                </div>
                <div className="flex-1 min-w-0 md:px-8 lg:px-0 xl:col-span-6">
                  <div className=" items-center px-6 py-4 md:ml-[10rem] md:max-w-3xl lg:mx-0 lg:max-w-none lg:ml-[1.5rem] xl:px-0 2xl:ml-[2.5rem] hidden md:flex ">
                    {" "}
                    <SearchBar />
                  </div>
                </div>
                <div className="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden">
                  <Popover.Button className="relative inline-flex items-center justify-center p-2 -mx-2 text-gray-400 rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                    )}
                  </Popover.Button>
                </div>
                <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                  <button
                    type="button"
                    onClick={handleLogout}
                    className="relative flex-shrink-0 p-1 ml-5 text-logout bg-white rounded-full "
                  >
                    <span className="flex items-center space-x-[6px]">
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.6691 16L7.66913 12M7.66913 12L11.6691 8M7.66913 12H21.6691M16.6691 16V17C16.6691 17.7956 16.3531 18.5587 15.7904 19.1213C15.2278 19.6839 14.4648 20 13.6691 20H6.66913C5.87348 20 5.11042 19.6839 4.54781 19.1213C3.9852 18.5587 3.66913 17.7956 3.66913 17V7C3.66913 6.20435 3.9852 5.44129 4.54781 4.87868C5.11042 4.31607 5.87348 4 6.66913 4H13.6691C14.4648 4 15.2278 4.31607 15.7904 4.87868C16.3531 5.44129 16.6691 6.20435 16.6691 7V8"
                          stroke="#111827"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span className="font-medium text-sm ">Log out</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="max-w-3xl px-2 pt-2 pb-3 mx-auto space-y-1 sm:px-4">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    onClick={handleLogout}
                    aria-current={item.current ? "page" : undefined}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 text-gray-900"
                        : "hover:bg-gray-50",
                      "block rounded-md py-2 px-3 text-base font-medium"
                    )}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </Popover.Panel>
          </div>
        )}
      </Popover>
    </div>
  );
};

export default Header;
