import React from "react";
import Header from "../Layout/Header";
import DepartmentForm from "./DepartmentForm";
import Breadcrumb from "../Layout/Breadcrumb";
import { departmentBreadcrumbs } from "../../types/breadcrumbNavType";

const DepartmentPage: React.FC = () => {
  return (
    <>
      <Header />
      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 mt-7 pb-20">
        <h2 className="text-2xl font-normal text-black">Add Department</h2>
        <p className="text-sm font-medium text-zinc-500 mt-6">
          <Breadcrumb items={departmentBreadcrumbs} />
        </p>

        <div className=" mt-2.5 bg-white shadow-sm  shadow-slate-400/40 flex  rounded-lg mx-auto justify-center flex-col  md:col-span-2">
          <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0  mx-auto max-w-2xl">
            <h2 className="text-gray-900 font-bold text-lg">
              Department overview
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              Summary of department
            </p>

            <DepartmentForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentPage;
