import React, { useState, useEffect } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
import topLogo from "../../assets/topLogo.png";
import mapImage from "../../assets/mapImage.png";
import bottomImage from "../../assets/bottomLogo.png";
import { AUTH } from "../../lib/api";
import { useNavigate } from "react-router-dom";
import { SessionStorage } from "../../components/SessionStorage/SessionStorage";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}
const LogIn: React.FC = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    setLoginError("");
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    rememberMe: Yup.boolean().required(),
  });

  const initialValues: FormValues = {
    email: "",
    password: "",
    rememberMe: false,
  };
  const navigate = useNavigate();
  const handleSubmit = async (
    { email, password, rememberMe }: FormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}${AUTH.LOGIN}`,
        { email, password, rememberMe },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        const responseData = response.data.data.user;
        SessionStorage.login(responseData);
        navigate("/dashboard");
        resetForm();
      } else {
        throw new Error("Invalid email or password");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setLoginError(
            error.response.data.message || "An error occurred while logging in "
          );
        } else {
          setLoginError("An error occurred while processing your request");
        }
      } else {
        setLoginError("An unexpected error occurred");
      }
    }
  };

  return (
    <div className=" flex flex-col lg:flex-row justify-center min-h-screen items-center ">
      <div className="w-full lg:w-9/12 h-full px-4 py-8 lg:py-12 sm:px-6 lg:px-20 xl:px-24 flex justify-center items-center ">
        <div className="w-full max-w-sm">
          <div className="flex flex-col items-center mb-6">
            <h1 className="underline text-logo pb-6">BBMP</h1>
            <h2 className="text-xl font-bold leading-8 tracking-tight text-gray-800">
              Welcome Back!
            </h2>
            <p className="text-sm leading-6 text-gray-500">
              Log in to your account
            </p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form className="space-y-6">
                <Field
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Email"
                  className={`block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                    formik.errors.email && formik.touched.email
                      ? "border-red-500"
                      : ""
                  }`}
                />
                <ErrorMessage
                  name="email"
                  component="span"
                  className="text-red-500 text-xs mt-0"
                />

                <div className="mt-0 relative">
                  <Field
                    id="password"
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    autoComplete="current-password"
                    placeholder="Password"
                    className={`block w-full rounded-md border-0 px-2 py-1.5 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                      formik.errors.password && formik.touched.password
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-3 py-1.5 flex items-center text-gray-400"
                    onClick={togglePasswordVisibility}
                  >
                    {passwordVisible ? <FiEyeOff /> : <FiEye />}
                  </button>
                </div>
                <ErrorMessage
                  name="password"
                  component="span"
                  className="text-red-500 text-xs mt-"
                />

                {loginError && (
                  <div className="text-red-500 text-xs mt-0">{loginError}</div>
                )}

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="rememberMe"
                      name="rememberMe"
                      type="checkbox"
                      checked={formik.values.rememberMe}
                      onChange={formik.handleChange}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor="rememberMe"
                      className="ml-3 block text-sm leading-6 text-gray-700"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm leading-6">
                    <Link
                      to="/reset-password"
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={formik.isSubmitting}
                >
                  Sign in
                </button>
              </Form>
            )}
          </Formik>
          <div className="flex items-center justify-center mt-3 pt-6">
            <p className="mr-2 px-2">Powered by WizTap platform from</p>
            <img className="h-8 w-auto" src={bottomImage} alt="Your Company" />
          </div>
        </div>
      </div>
      <div className="w-full mx-12 gap-x-8 lg:w-7/12   hidden lg:flex justify-center items-center">
        <img
          className="h-auto max-h-80 mr-40"
          src={mapImage}
          alt="Karnataka Map"
        />
      </div>
    </div>
  );
};

export default LogIn;
