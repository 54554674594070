import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Switch } from "@headlessui/react";
import * as Yup from "yup";
import { DROPDOWN } from "../../constants/constants";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const UpdateApplication: React.FC<{
  handleClose: () => void;
  formData: any;
}> = ({ handleClose, formData }) => {
  const initialOptions: string[] = DROPDOWN.endUserOptions;

  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    formData.overview?.endUsers || []
  );
  const [options, setOptions] = useState<string[]>(initialOptions);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [enabled, setEnabled] = useState<boolean>(formData.isActive);

  const [stability, setStability] = useState<boolean>(
    formData.developmentAndMaintenance?.stability
  );
  const [ticketDump, setTicketDump] = useState<boolean>(
    formData.developmentAndMaintenance?.ticketDump
  );
  const [connectivityPlanning, setConnectivityPlanning] = useState<boolean>(
    formData.developmentAndMaintenance?.connectivityPlanning
  );

  const [releaseAndChange, setreleaseAndChange] = useState<boolean>(
    formData.developmentAndMaintenance?.releaseAndChange
  );

  const formik = useFormik({
    initialValues: formData,

    onSubmit: async (values, { resetForm }) => {
      const dataToSend = {
        ...values,
        teamAndSupport: values.teamAndSupport.filter(
          (member: any) => member.name && member.skills
        ),
      };
      delete dataToSend._id;
      delete dataToSend.createdAt;
      delete dataToSend.updatedAt;
      delete dataToSend.department;
      delete dataToSend.ragStatus;

      if (dataToSend.overview && dataToSend.overview.releaseDate) {
        dataToSend.overview.releaseDate = new Date(
          dataToSend.overview.releaseDate
        ).toISOString();
      } else {
        dataToSend.overview.releaseDate = "";
      }

      dataToSend.teamAndSupport = dataToSend.teamAndSupport.map((obj: any) => {
        const { _id, ...objWithoutId } = obj;
        return objWithoutId;
      });
      try {
        const response = await axios.put(
          `${BACKEND_URL}/application/update/${formData._id}`,
          dataToSend,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        if (response.status === 200 || response.status === 201) {
          handleClose();
          window.location.reload();
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    },
  });
  const handleToggle = () => {
    const newValue = !enabled;
    setEnabled(newValue);
    formik.setFieldValue("isActive", newValue);
  };
  const handleStabilityToggle = () => {
    const newValue = !stability;
    setStability(newValue);
    formik.setFieldValue("developmentAndMaintenance.stability", newValue);
  };

  const handleTicketDumpToggle = () => {
    const newValue = !ticketDump;
    setTicketDump(newValue);
    formik.setFieldValue("developmentAndMaintenance.ticketDump", newValue);
  };

  const handlesConnectivityPlanningToggle = () => {
    setConnectivityPlanning(!connectivityPlanning);
    formik.setFieldValue(
      "developmentAndMaintenance.connectivityPlanning",
      connectivityPlanning
        ? ""
        : formik.values.developmentAndMaintenance?.connectivityPlanning
    );
  };

  const handlesreleaseAndChangeToggle = () => {
    setreleaseAndChange(!releaseAndChange);
    formik.setFieldValue(
      "developmentAndMaintenance.releaseAndChange",
      releaseAndChange
        ? ""
        : formik.values.developmentAndMaintenance?.releaseAndChange
    );
  };

  const handleAddMember = () => {
    const isAnyEmpty = formik.values.teamAndSupport.some(
      (member: any) => !member.name || !member.skills
    );
    if (!isAnyEmpty) {
      formik.setFieldValue("teamAndSupport", [
        ...formik.values.teamAndSupport,
        { name: "", skills: "" },
      ]);
    }
  };
  useEffect(() => {
    if (searchTerm) {
      const filteredOptions = initialOptions.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setOptions(filteredOptions);
    } else {
      setOptions(initialOptions);
    }
  }, [searchTerm]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleSelectOption = (option: string) => {
    if (!selectedUsers.includes(option)) {
      const newSelectedUsers = [...selectedUsers, option];
      const uniqueSelectedUsers = Array.from(new Set(newSelectedUsers));
      setSelectedUsers(uniqueSelectedUsers);
      formik.setFieldValue("overview.endUsers", uniqueSelectedUsers);
    }
    setSearchTerm("");
    setIsDropdownVisible(false);
  };

  const handleRemoveOption = (
    opt: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const newSelectedUsers = selectedUsers.filter((option) => option !== opt);
    setSelectedUsers(newSelectedUsers);
    formik.setFieldValue("overview.endUsers", newSelectedUsers);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-5">
        <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
          Application Overview
        </h2>
        <p className="text-gray-600 font-normal mt-1 text-base">
          Summary of application details and usage{" "}
        </p>
      </div>
      <div className="my-8 flex items-center">
        <span className="mr-2">Application Status :</span>
        <Switch
          checked={enabled}
          onChange={handleToggle}
          className={classNames(
            enabled ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex h-5 w-11  flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out "
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? "translate-x-6" : "translate-x-0",
              "pointer-events-none inline-block h-4 w-4  transform rounded-full bg-white shadow transition duration-200 ease-in-out"
            )}
          />
        </Switch>
        <span className="ml-2">{enabled ? "Active" : "InActive"}</span>
      </div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
        <div>
          <label
            htmlFor="appName"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Name<span className="text-red-500"> *</span>
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="appName"
              name="overview.appName"
              value={formik.values.overview?.appName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Name"
              className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="link"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Link
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="link"
              name="overview.link"
              value={formik.values.overview?.link}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Application URL"
              className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 mt-8 gap-x-6 gap-y-9 sm:grid-cols-2">
        <div>
          <label
            htmlFor="appOwner"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Owner<span className="text-red-500"> *</span>
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="appOwner"
              name="overview.appOwner"
              value={formik.values.overview?.appOwner}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Name of the application appOwner"
              className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
        <div>
          <div className="grid grid-cols-1  gap-x-6 gap-y-9 sm:grid-cols-2">
            <div>
              <label
                htmlFor="releaseVersion"
                className="block text-sm font-medium leading-6 text-gray-700"
              >
                Version
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="releaseVersion"
                  name="overview.releaseVersion"
                  value={formik.values.overview?.releaseVersion}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Release releaseVersion"
                  className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="releaseDate"
                className="block text-sm font-medium leading-6 text-gray-700"
              >
                Version Date
              </label>
              <div className="mt-1">
                <input
                  type="date"
                  id="releaseDate"
                  name="overview.releaseDate"
                  value={
                    formik.values.overview?.releaseDate
                      ? formik.values.overview?.releaseDate.split("T")[0]
                      : ""
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Release Date"
                  className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="sm:col-span-2">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Description
          </label>

          <div className="mt-1">
            <textarea
              id="description"
              name="overview.description"
              value={formik.values.overview?.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={2000}
              placeholder="Brief description of application"
              className="block w-full  h-24 custom-scrollbar rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>

        <div className="sm:col-span-2">
          <label
            htmlFor="usage"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Usage
          </label>
          <div className="mt-1">
            <textarea
              id="usage"
              name="overview.usage"
              value={formik.values.overview?.usage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={2000}
              placeholder="Application usage"
              className="block w-full h-24 custom-scrollbar rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 mt-8 mb-8 gap-x-6 gap-y-6 sm:grid-cols-2">
        <div>
          <label
            htmlFor="features"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Key Features
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="features"
              name="overview.features"
              value={formik.values.overview?.features || ""}
              onChange={(e) => {
                const newValue = e.target.value.split(" , ");
                formik.setFieldValue("overview.features", newValue);
              }}
              onBlur={formik.handleBlur}
              placeholder="Key features of the application"
              className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="sourceCodeLink"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Source Code Link
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="sourceCodeLink"
              name="overview.sourceCodeLink"
              value={formik.values.overview?.sourceCodeLink}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Source code link"
              className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
      </div>
      <div>
        <label
          htmlFor="endUsers"
          className="block text-sm font-medium leading-6 text-gray-700"
        >
          End Users
        </label>
        <div ref={wrapperRef} className="mt-1">
          <div className="flex flex-wrap gap-2">
            {selectedUsers.map((option, index) => (
              <span
                key={index}
                className=" text-[#4B79EF] rounded px-2 py-1 flex items-center text-sm bg-[#E5EAFF]"
              >
                {option}
                <button
                  onClick={(e) => handleRemoveOption(option, e)}
                  className="text-lg color text-[#4B79EF] ml-2"
                >
                  ×
                </button>
              </span>
            ))}
          </div>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={() => setIsDropdownVisible(true)}
            className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm mt-4 placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            placeholder="Search..."
          />
          {isDropdownVisible && (
            <ul className="border border-gray-300 max-h-40 overflow-auto">
              {options.map((option, index) => (
                <li
                  key={index}
                  className="p-2 hover:bg-gray-200 cursor-pointer text-sm"
                  onClick={() => {
                    handleSelectOption(option);
                  }}
                >
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="border border-t border-gray-300  max-w-5xl  mt-8"></div>
      <div className="my-8">
        <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
          Architecture and Technology
        </h2>
        <p className="text-gray-700 font-normal mt-1 text-base">
          Details on application versioning, management, and maintenance.{" "}
        </p>
      </div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
        <div>
          <label
            htmlFor="applicationType"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Type of application<span className="text-red-500"> *</span>
          </label>
          <div className="mt-1">
            <select
              name="architectureAndTechnology.applicationType"
              value={formik.values.architectureAndTechnology?.applicationType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="block w-full rounded-md  py-2.5 px-3 text-gray-900 shadow-sm  border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            >
              <option value="" disabled hidden>
                Select Type
              </option>
              <option value="Home Grown" className="text-gray-900">
                Home Grown
              </option>
              <option value="Package Application" className="text-gray-900">
                Package Application
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-x-6 mt-8 gap-y-6 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <label
            htmlFor="technicalArchitecture"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Business/ technical architecture
          </label>

          <div className="mt-1">
            <textarea
              id="technicalArchitecture"
              name="architectureAndTechnology.technicalArchitecture"
              value={
                formik.values.architectureAndTechnology?.technicalArchitecture
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={2000}
              placeholder="Provide the business / logical architecture"
              className="block w-full h-24 custom-scrollbar rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="technologyStack"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Technology Stack{" "}
          </label>
          <div className="mt-1">
            <textarea
              id="technologyStack"
              name="architectureAndTechnology.technologyStack"
              value={
                formik.values.architectureAndTechnology?.technologyStack.join(
                  ", "
                ) || ""
              }
              onChange={(e) => {
                const newValue = e.target.value.split(", ");
                formik.setFieldValue(
                  "architectureAndTechnology.technologyStack",
                  newValue
                );
              }}
              onBlur={formik.handleBlur}
              maxLength={2000}
              placeholder="Enter technologies separated by commas"
              className="block w-full h-24 rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="featureEnhancement"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Feature enhancement Process{" "}
          </label>
          <div className="mt-1">
            <textarea
              id="featureEnhancement"
              name="architectureAndTechnology.featureEnhancement"
              value={
                formik.values.architectureAndTechnology?.featureEnhancement
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={2000}
              placeholder="New feature enhancements handled in the application"
              className="block w-full h-24 custom-scrollbar rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="softwareTools"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Software Tools{" "}
          </label>
          <div className="mt-1">
            <textarea
              id="softwareTools"
              name="architectureAndTechnology.softwareTools"
              value={formik.values.architectureAndTechnology?.softwareTools}
              onChange={(e) =>
                formik.setFieldValue(
                  "architectureAndTechnology.softwareTools",
                  e.target.value.split(" , ")
                )
              }
              onBlur={formik.handleBlur}
              maxLength={2000}
              placeholder="Enter software tools separated by commas"
              className="block w-full h-24 custom-scrollbar rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
      </div>
      <div className="border border-t border-gray-300  max-w-5xl  mt-8"></div>
      <div className="my-8">
        <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
          Development and Maintenance{" "}
        </h2>
        <p className="text-gray-600 font-normal mt-1 text-base">
          Details on application versioning, management, and maintenance.{" "}
        </p>
      </div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
        <div>
          <label
            htmlFor="managedBy"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Managed by <span className="text-red-500"> *</span>
          </label>
          <div className="mt-1">
            <select
              name="developmentAndMaintenance.managedBy"
              value={formik.values.developmentAndMaintenance?.managedBy}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className=" select-style block w-full rounded-md  py-2.5 px-3 text-gray-900 shadow-sm  border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            >
              <option value="" disabled hidden>
                Select Type
              </option>
              <option
                value="BBMP"
                className="text-gray-900  border-gray-300 p-3"
              >
                BBMP
              </option>
              <option
                value="Vendor"
                className="text-gray-900 border border-gray-300 py-2.5 px-3"
              >
                Vendor
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 mt-8 gap-x-6 gap-y-9 sm:grid-cols-2">
        <div>
          <label
            htmlFor="managerName"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Name <span className="text-red-500"> *</span>
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="developmentAndMaintenance.managerName"
              value={formik.values.developmentAndMaintenance?.managerName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Manager of the application"
              className="block w-full rounded-md  py-2 px-3 text-gray-900 shadow-sm   placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="managerContactNumber"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Contact number
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="developmentAndMaintenance.managerContactNumber"
              value={
                formik.values.developmentAndMaintenance?.managerContactNumber
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Manager contact number"
              maxLength={10}
              className="block w-full rounded-md  py-2 px-3 text-gray-900 shadow-sm   placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
      </div>
      <div className="border border-t border-gray-300 w-full mt-8"></div>
      <div className="grid grid-cols-1 mt-8 gap-x-6  sm:grid-cols-2">
        <div>
          <label
            htmlFor="softwareMethodology"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Software methodology
          </label>
          <div className="mt-1">
            <select
              name="developmentAndMaintenance.softwareMethodology"
              value={
                formik.values.developmentAndMaintenance?.softwareMethodology
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="block w-full rounded-md  py-2.5 px-3 text-gray-900 shadow-sm  border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            >
              <option value="" disabled hidden>
                Select Type
              </option>
              <option value="Agile" className="text-gray-900">
                Agile
              </option>
              <option value="Waterfall" className="text-gray-900">
                Water fall
              </option>
              <option value="Lean" className="text-gray-900">
                Lean
              </option>
              <option value="NA" className="text-gray-900">
                Not Applicable
              </option>
            </select>
          </div>
        </div>
        <div>
          <div className="sm:col-span-6 flex items-center gap-11 mt-8">
            <div className="sm:col-span-3 ">
              <div className=" flex items-center">
                <p className="mr-2 text-sm text-gray-700 font-medium">
                  Stability
                </p>

                <Switch
                  checked={stability}
                  onChange={handleStabilityToggle}
                  className={classNames(
                    stability ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex w-8 h-4 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      stability ? "translate-x-4" : "translate-x-0",
                      "pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>

                <p className="ml-2 relative text-sm font-medium text-gray-600">
                  {stability ? true : false}
                </p>
              </div>
            </div>
            <div className="sm:col-span-3 relative ">
              <div className=" flex items-center">
                <p className="mr-2 text-sm text-gray-700 font-medium">
                  Ticket dump
                </p>

                <Switch
                  checked={ticketDump}
                  onChange={handleTicketDumpToggle}
                  className={classNames(
                    ticketDump ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex w-8 h-4 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      ticketDump ? "translate-x-4" : "translate-x-0",
                      "pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>

                <p className="ml-2 relative text-sm font-medium text-gray-600">
                  {ticketDump ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 mt-8 gap-x-6 gap-y-9 sm:grid-cols-2">
        <div>
          <label
            htmlFor="sourceCodeManager"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Source Code Management
          </label>
          <div className="mt-1">
            <select
              name="developmentAndMaintenance.sourceCodeManager"
              value={formik.values.developmentAndMaintenance?.sourceCodeManager}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="block w-full rounded-md  py-2.5 px-3 text-gray-900 shadow-sm  border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            >
              <option value="" disabled hidden>
                Select Type
              </option>
              <option value="BBMP" className="text-gray-900">
                BBMP
              </option>
              <option value="Vendor" className="text-gray-900">
                Vendor
              </option>
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="codeStorage"
            className="block text-sm font-medium leading-6 text-gray-700"
          >
            Code stored in
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="developmentAndMaintenance.codeStorage"
              value={formik.values.developmentAndMaintenance?.codeStorage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Application code stored"
              className="block w-full rounded-md  py-2 px-3 text-gray-900 shadow-sm   placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 mt-8 gap-x-6 gap-y-9 sm:grid-cols-2 ">
        <div className="sm:col-span-2">
          <label
            htmlFor="connectivityPlanning"
            className=" items-center flex justify-between text-sm font-medium leading-6 text-gray-700"
          >
            Connectivity planning{" "}
            <div className=" flex items-center">
              <Switch
                checked={connectivityPlanning}
                onChange={handlesConnectivityPlanningToggle}
                className={classNames(
                  connectivityPlanning ? "bg-indigo-600" : "bg-gray-200",
                  "relative inline-flex w-8 h-4   flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    connectivityPlanning ? "translate-x-4" : "translate-x-0",
                    "pointer-events-none  inline-block h-3 w-3  transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <p className="ml-2 relative text-sm font-medium text-gray-600">
                {connectivityPlanning ? "Yes" : "No"}
              </p>
            </div>
          </label>
          <div className="mt-1">
            <textarea
              name="developmentAndMaintenance.connectivityPlanning"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={
                formik.values.developmentAndMaintenance?.connectivityPlanning
              }
              maxLength={2000}
              disabled={!connectivityPlanning}
              placeholder="If yes, How is connectivity planned for the application w.r.t deployment, performance, security, Is there a process followed today"
              className="block w-full h-24 rounded-md custom-scrollbar py-2 px-3 text-gray-900 shadow-sm   placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            ></textarea>
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="releaseAndChange"
            className=" items-center flex justify-between text-sm font-medium leading-6 text-gray-700"
          >
            Release Management and Change Control{" "}
            <div className=" flex items-center">
              <Switch
                checked={releaseAndChange}
                onChange={handlesreleaseAndChangeToggle}
                className={classNames(
                  releaseAndChange ? "bg-indigo-600" : "bg-gray-200",
                  "relative inline-flex w-8 h-4  flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    releaseAndChange ? "translate-x-4" : "translate-x-0",
                    "pointer-events-none  inline-block h-3 w-3  transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <p className="ml-2 relative text-sm font-medium text-gray-600">
                {releaseAndChange ? "Yes" : "No"}
              </p>
            </div>
          </label>
          <div className="mt-1">
            <textarea
              name="developmentAndMaintenance.releaseAndChange"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.developmentAndMaintenance?.releaseAndChange}
              maxLength={2000}
              disabled={!releaseAndChange}
              placeholder="If yes, What is the current Release Management and Change Control processesWhat is the reliability, availability and maintainability of the application"
              className="block w-full h-24 rounded-md custom-scrollbar  py-2 px-3 text-gray-900 shadow-sm   placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
            ></textarea>
          </div>
        </div>
      </div>
      <div className="border border-t border-gray-300  max-w-5xl  mt-8"></div>
      <div className="my-8">
        <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
          Team and Support{" "}
        </h2>
        <p className="text-gray-600 font-normal mt-1 text-base">
          Information about the responsible team and support{" "}
        </p>
      </div>
      <div>
        {formik.values.teamAndSupport.map((teamMember: any, index: number) => (
          <div
            key={index}
            className="grid grid-cols-1 mt-3 gap-x-6 gap-y-6 sm:grid-cols-3"
          >
            <div className="sm:col-span-1">
              {index === 0 && (
                <label
                  htmlFor={`name-${index}`}
                  className="block text-sm font-medium leading-6 text-gray-700"
                >
                  Team member name
                </label>
              )}
              <div className="mt-1">
                <input
                  type="text"
                  id={`name-${index}`}
                  name={`teamAndSupport.${index}.name`}
                  value={teamMember.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Name"
                  className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              {index === 0 && (
                <label
                  htmlFor={`skills-${index}`}
                  className="block text-sm font-medium leading-6 text-gray-700"
                >
                  Skills
                </label>
              )}
              <div className="mt-1">
                <input
                  type="text"
                  id={`skills-${index}`}
                  name={`teamAndSupport.${index}.skills`}
                  value={teamMember.skills}
                  onChange={(e) => {
                    const newValue = e.target.value.split(",");
                    formik.setFieldValue(
                      `teamAndSupport.${index}.skills`,
                      newValue
                    );
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Skills"
                  className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={handleAddMember}
          className="rounded-sm bg-white px-3 py-2 text-sm font-semibold w-24 text-indigo-500 border border-indigo-500 mt-10"
        >
          Add
        </button>
        <button
          type="submit"
          className="rounded-md w-32 h-10 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm mt-10"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default UpdateApplication;
