import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DevMaintenanceData } from "../../types/aplicationCreateTypes";
import { useAppForm } from "../../context/AppFormContext";
import { devMaintenanceSteps } from "../../types/formStepsPropsTypes";
import { applicationBreadcrumbs } from "../../types/breadcrumbNavType";

import { DevMaintenanceProps } from "../../types/stageComponentTypes";
import Header from "../Layout/Header";
import FormSteps from "../FormStage/FormStage";
import Breadcrumb from "../Layout/Breadcrumb";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const validationSchema = Yup.object().shape({
  managerName: Yup.string()
    .required("Name is required")
    .matches(/^[a-zA-Z\s\W]+$/, "Name can only contain alphabets"),
  managerContactNumber: Yup.string()
    .matches(/^[0-9]*$/, "Only numbers are allowed")
    .max(10, "Maximum of 10 digits are allowed")
    .min(10, "Minimum of 10 digits are allowed"),
  managedBy: Yup.string().required("Please select the option"),
});

const DevelopmentMaintenance: React.FC<DevMaintenanceProps> = ({
  onNext,
  onPrev,
}) => {
  const { state, dispatch } = useAppForm();
  const [stability, setStability] = useState<boolean>(
    (state.developmentAndMaintenance as DevMaintenanceData)?.stability
  );
  const [ticketDump, setTicketDump] = useState<boolean>(
    (state.developmentAndMaintenance as DevMaintenanceData)?.ticketDump
  );
  const [connectivityPlanning, setConnectivityPlanning] = useState<boolean>(
    !!(state.developmentAndMaintenance as DevMaintenanceData)
      ?.connectivityPlanning
  );

  const [releaseAndChange, setreleaseAndChange] = useState<boolean>(
    !!(state.developmentAndMaintenance as DevMaintenanceData)?.releaseAndChange
  );
  const formik = useFormik<DevMaintenanceData>({
    initialValues: {
      managedBy:
        (state.developmentAndMaintenance as DevMaintenanceData)?.managedBy ||
        "",
      managerName:
        (state.developmentAndMaintenance as DevMaintenanceData)?.managerName ||
        "",
      managerContactNumber:
        (state.developmentAndMaintenance as DevMaintenanceData)
          ?.managerContactNumber || "",
      softwareMethodology:
        (state.developmentAndMaintenance as DevMaintenanceData)
          ?.softwareMethodology || "",
      stability:
        (state.developmentAndMaintenance as DevMaintenanceData)?.stability ||
        false,
      ticketDump:
        (state.developmentAndMaintenance as DevMaintenanceData)?.ticketDump ||
        false,
      sourceCodeManager:
        (state.developmentAndMaintenance as DevMaintenanceData)
          ?.sourceCodeManager || "",
      codeStorage:
        (state.developmentAndMaintenance as DevMaintenanceData)?.codeStorage ||
        "",
      connectivityPlanning:
        (state.developmentAndMaintenance as DevMaintenanceData)
          ?.connectivityPlanning || "",
      releaseAndChange:
        (state.developmentAndMaintenance as DevMaintenanceData)
          ?.releaseAndChange || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch({ type: "SET_DEV_MAINTENANCE", payload: values });
      onNext();
    },
  });

  useEffect(() => {
    formik.setFieldValue("stability", stability);
  }, [stability]);

  useEffect(() => {
    formik.setFieldValue("ticketDump", ticketDump);
  }, [ticketDump]);

  const handleStabilityToggle = () => {
    setStability((prevStability) => !prevStability);
  };

  const handleTicketDumpToggle = () => {
    setTicketDump((prevTicketDump) => !prevTicketDump);
  };

  const handlesConnectivityPlanningToggle = () => {
    setConnectivityPlanning(!connectivityPlanning);
    formik.setFieldValue(
      "connectivityPlanning",
      connectivityPlanning ? "" : formik.values.connectivityPlanning
    );
  };

  const handlesreleaseAndChangeToggle = () => {
    setreleaseAndChange(!releaseAndChange);
    formik.setFieldValue(
      "releaseAndChange",
      releaseAndChange ? "" : formik.values.releaseAndChange
    );
  };

  return (
    <>
      <Header />
      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 mt-5 pb-20">
        <h2 className="text-2xl font-normal text-black mt-6">
          Add Application
        </h2>
        <p className="text-sm font-medium text-zinc-500 mt-4">
          {" "}
          <Breadcrumb items={applicationBreadcrumbs} />
        </p>{" "}
        <div className=" mt-6 bg-white shadow-sm  shadow-slate-400/40 flex  rounded-lg mx-auto justify-center flex-col  md:col-span-2">
          <FormSteps steps={devMaintenanceSteps} />{" "}
          <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0   mx-auto max-w-2xl">
            <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
              Development and Maintenance{" "}
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              Details on application versioning, management, and maintenance.{" "}
            </p>

            <form
              onSubmit={formik.handleSubmit}
              className="mt-8 max-w-2xl sm:mt-18"
            >
              <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="managedBy"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Managed by <span className="text-red-500"> *</span>
                  </label>
                  <div className="mt-1">
                    <select
                      name="managedBy"
                      value={formik.values.managedBy}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className=" select-style block w-full rounded-md  py-2.5 px-3 text-gray-900 shadow-sm  border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    >
                      <option value="" disabled hidden>
                        Select Type
                      </option>
                      <option
                        value="BBMP"
                        className="text-gray-900  border-gray-300 p-3"
                      >
                        BBMP
                      </option>
                      <option
                        value="Vendor"
                        className="text-gray-900 border border-gray-300 py-2.5 px-3"
                      >
                        Vendor
                      </option>
                    </select>
                    {formik.touched.managedBy && formik.errors.managedBy ? (
                      <div className="absolute text-red-500 text-sm mt-1">
                        {formik.errors.managedBy}
                      </div>
                    ) : null}{" "}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 mt-8 gap-x-6 gap-y-9 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="managerName"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Name <span className="text-red-500"> *</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="managerName"
                      value={formik.values.managerName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Manager of the application"
                      className="block w-full rounded-md  py-2 px-3 text-gray-900 shadow-sm   placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                    {formik.touched.managerName && formik.errors.managerName ? (
                      <div className="absolute text-red-500 text-sm mt-1">
                        {formik.errors.managerName}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="managerContactNumber"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Contact number
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="managerContactNumber"
                      value={formik.values.managerContactNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Manager contact number"
                      maxLength={10}
                      className="block w-full rounded-md  py-2 px-3 text-gray-900 shadow-sm   placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                    {formik.touched.managerContactNumber &&
                    formik.errors.managerContactNumber ? (
                      <div className="absolute text-red-500 text-sm mt-1">
                        {formik.errors.managerContactNumber}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="border border-t border-gray-300 w-full mt-8"></div>
              <div className="grid grid-cols-1 mt-8 gap-x-6  sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="softwareMethodology"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Software methodology
                  </label>
                  <div className="mt-1">
                    <select
                      name="softwareMethodology"
                      value={formik.values.softwareMethodology}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="block w-full rounded-md  py-2.5 px-3 text-gray-900 shadow-sm  border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    >
                      <option value="" disabled hidden>
                        Select Type
                      </option>
                      <option value="Agile" className="text-gray-900">
                        Agile
                      </option>
                      <option value="Waterfall" className="text-gray-900">
                        Water fall
                      </option>
                      <option value="Lean" className="text-gray-900">
                        Lean
                      </option>
                      <option value="NA" className="text-gray-900">
                        Not Applicable
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <div className="sm:col-span-6 flex items-center gap-11 mt-8">
                    <div className="sm:col-span-3 absolute">
                      <div className=" flex items-center">
                        <p className="mr-2 text-sm text-gray-700 font-medium">
                          Stability
                        </p>

                        <Switch
                          checked={stability}
                          onChange={handleStabilityToggle}
                          className={classNames(
                            stability ? "bg-indigo-600" : "bg-gray-200",
                            "relative inline-flex w-8 h-4 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              stability ? "translate-x-4" : "translate-x-0",
                              "pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                            )}
                          />
                        </Switch>

                        <p className="ml-2 relative text-sm font-medium text-gray-600">
                          {stability ? true : false}
                        </p>
                      </div>
                    </div>
                    <div className="sm:col-span-3 relative md:left-44 sm:left-32 left-44">
                      <div className=" flex items-center">
                        <p className="mr-2 text-sm text-gray-700 font-medium">
                          Ticket dump
                        </p>

                        <Switch
                          checked={ticketDump}
                          onChange={handleTicketDumpToggle}
                          className={classNames(
                            ticketDump ? "bg-indigo-600" : "bg-gray-200",
                            "relative inline-flex w-8 h-4 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              ticketDump ? "translate-x-4" : "translate-x-0",
                              "pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                            )}
                          />
                        </Switch>

                        <p className="ml-2 relative text-sm font-medium text-gray-600">
                          {ticketDump ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 mt-8 gap-x-6 gap-y-9 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="sourceCodeManager"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Source Code Management
                  </label>
                  <div className="mt-1">
                    <select
                      name="sourceCodeManager"
                      value={formik.values.sourceCodeManager}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="block w-full rounded-md  py-2.5 px-3 text-gray-900 shadow-sm  border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    >
                      <option value="" disabled hidden>
                        Select Type
                      </option>
                      <option value="BBMP" className="text-gray-900">
                        BBMP
                      </option>
                      <option value="Vendor" className="text-gray-900">
                        Vendor
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="codeStorage"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Code stored in
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="codeStorage"
                      value={formik.values.codeStorage}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Application code stored"
                      className="block w-full rounded-md  py-2 px-3 text-gray-900 shadow-sm   placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 mt-8 gap-x-6 gap-y-9 sm:grid-cols-2 ">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="connectivityPlanning"
                    className=" items-center flex justify-between text-sm font-medium leading-6 text-gray-700"
                  >
                    Connectivity planning{" "}
                    <div className=" flex items-center">
                      <Switch
                        checked={connectivityPlanning}
                        onChange={handlesConnectivityPlanningToggle}
                        className={classNames(
                          connectivityPlanning
                            ? "bg-indigo-600"
                            : "bg-gray-200",
                          "relative inline-flex w-8 h-4   flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out "
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            connectivityPlanning
                              ? "translate-x-4"
                              : "translate-x-0",
                            "pointer-events-none  inline-block h-3 w-3  transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                      <p className="ml-2 relative text-sm font-medium text-gray-600">
                        {connectivityPlanning ? "Yes" : "No"}
                      </p>
                    </div>
                  </label>
                  <div className="mt-1">
                    <textarea
                      name="connectivityPlanning"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.connectivityPlanning}
                      maxLength={2000}
                      disabled={!connectivityPlanning}
                      placeholder="If yes, How is connectivity planned for the application w.r.t deployment, performance, security, Is there a process followed today"
                      className="block w-full h-24 rounded-md  py-2 px-3 text-gray-900 shadow-sm   placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    ></textarea>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="releaseAndChange"
                    className=" items-center flex justify-between text-sm font-medium leading-6 text-gray-700"
                  >
                    Release Management and Change Control{" "}
                    <div className=" flex items-center">
                      <Switch
                        checked={releaseAndChange}
                        onChange={handlesreleaseAndChangeToggle}
                        className={classNames(
                          releaseAndChange ? "bg-indigo-600" : "bg-gray-200",
                          "relative inline-flex w-8 h-4   flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out "
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            releaseAndChange
                              ? "translate-x-4"
                              : "translate-x-0",
                            "pointer-events-none  inline-block h-3 w-3  transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                      <p className="ml-2 relative text-sm font-medium text-gray-600">
                        {releaseAndChange ? "Yes" : "No"}
                      </p>
                    </div>
                  </label>
                  <div className="mt-1">
                    <textarea
                      name="releaseAndChange"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.releaseAndChange}
                      maxLength={2000}
                      disabled={!releaseAndChange}
                      placeholder="If yes, What is the current Release Management and Change Control processesWhat is the reliability, availability and maintainability of the application"
                      className="block w-full h-24 rounded-md  py-2 px-3 text-gray-900 shadow-sm   placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="flex items-center pb-10 sm:pb-0 justify-end mt-9 mb-8 gap-x-3 flex-end">
                <button
                  type="button"
                  onClick={() => {
                    onPrev();
                  }}
                  className="text-sm w-32 h-10 font-semibold leading-6 text-gray-900"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="rounded-md w-32 h-10 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DevelopmentMaintenance;
