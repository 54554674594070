import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
interface BreadcrumbProps {
  items: { name: string; href?: string; current?: boolean }[];
}
const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const navigate = useNavigate();

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        {items.map((item, index) => (
          <li key={index}>
            <div className="flex items-center">
              {index !== 0 && (
                <svg
                  className="rtl:rotate-180 w-1.5 h-2.5 text-gray-400 mx-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              )}
              {index === 0 ? (
                <Link
                  to="/dashboard"
                  className={`inline-flex ${
                    item.current ? "cursor-pointer" : ""
                  } items-center text-sm underline font-medium text-zinc-500  underline-offset-1 cursor-pointer `}
                >
                  Home
                </Link>
              ) : item.href ? (
                <div
                  className={`text-sm font-medium  underline underline-offset-2 text-zinc-500 md:ms-2   `}
                >
                  {item.name}
                </div>
              ) : (
                <span
                  className={`text-sm font-medium  md:ms-2 underline-offset-2 text-zinc-500 cursor-pointer `}
                >
                  {item.name}
                </span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
