export const AUTH = {
  LOGIN: `/user/login`,
  LOGOUT: `/user/logout`,
  REGISTER: `/register`,
};
export const USER = {
  RESET: `/user/resetPassword`,
};

export const APPLICATIONDASHBOARD = {
  DEPARTMENT : "/department/department-dashboard",
}

export const DEPARTMENT = {
  CREATE :"/department/create",
  UPDATE: "/department/update"
}
export const APPLICATION = {
  UPDATERAG : 'application/update-rag-status'
}

export const GETDEPTID = {
  GETID: "/department/get-by-id"
}

export const DOCUMENT = {
  UPLOAD: "/document/upload",
  DOWNLOAD : "/document"
}
export default process.env.REACT_APP_BACKEND_URL;
