import React, { useState, useEffect } from "react";

import {
  ApplicationDocument,
  TeamMember,
} from "../../types/aplicationCreateTypes";
import axios from "axios";
import { useParams } from "react-router-dom";
import Header from "../Layout/Header";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Breadcrumb from "../Layout/Breadcrumb";
import UpdateApplication from "../UpdateApplication/UpdateApplication";
import { DOCUMENT } from "../../lib/api";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ApplicationView: React.FC = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { id } = useParams();
  const formatDate = (date: Date | string): string => {
    if (date instanceof Date) {
      return date.toLocaleDateString("en-US");
    }
    return date;
  };
  const [formData, setFormData] = useState<any>({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [error , setError] = useState('')

  const userRole = "admin";

  const handleDownloadPDF = () => {
    const input = document.getElementById("pdf-content") as HTMLElement;

    html2canvas(input).then((canvas: any) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("image/png");

      pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
      pdf.save("application.pdf");
    });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_URL}/application/get-by-id/${id}`,
        { withCredentials: true }
      );
      const data = response.data.data;
      setFormData(data);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleDocumentDownload = async (applicationId : string, documentName  : string) => {
    try {
      const response = await axios.get(`${BACKEND_URL}${DOCUMENT.DOWNLOAD}/${applicationId}/${documentName}`, { withCredentials: true })
      const link = document.createElement('a');
      link.href = response.data.data.url;
      link.setAttribute('download', documentName);
      document.body.appendChild(link);
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();
      document.body.removeChild(link);
    } catch(error) {
      setError('Something went wrong!')
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  const handleEdit = () => {
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };
  const {
    overview,
    architectureAndTechnology,
    developmentAndMaintenance,
    teamAndSupport,
    documents,
    isActive,
  } = formData;
  const applicationBreadcrumbs = [
    { name: "Home", href: "/dashboard", current: false },
    { name: "Department", href: "/aplication-dashboard", current: false },
    { name: overview?.appName, href: "#", current: true },
  ];

  return (
    <>
      <Header />

      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 mt-5 pb-20">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-normal text-black mt-6">Report</h2>
            <p className="text-sm font-medium text-zinc-500 mt-4">
              <Breadcrumb items={applicationBreadcrumbs} />
            </p>{" "}
          </div>
          <div className="flex">
            {userRole === "admin" && (
              <button
                onClick={handleDownloadPDF}
                className="rounded-md w-32 h-10 flex items-center gap-4 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white "
              >
                Download
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.47266 9.66683C2.47266 9.25262 2.13687 8.91683 1.72266 8.91683C1.30844 8.91683 0.972656 9.25262 0.972656 9.66683L2.47266 9.66683ZM13.1393 9.66683C13.1393 9.25262 12.8035 8.91683 12.3893 8.91683C11.9751 8.91683 11.6393 9.25262 11.6393 9.66683L13.1393 9.66683ZM10.253 7.53049C10.5459 7.2376 10.5459 6.76273 10.253 6.46983C9.96009 6.17694 9.48522 6.17694 9.19232 6.46983L10.253 7.53049ZM7.05599 9.66683L6.52566 10.1972C6.81855 10.4901 7.29343 10.4901 7.58632 10.1972L7.05599 9.66683ZM4.91965 6.46983C4.62676 6.17694 4.15189 6.17694 3.85899 6.46983C3.5661 6.76273 3.5661 7.2376 3.85899 7.53049L4.91965 6.46983ZM7.80599 1.66683C7.80599 1.25262 7.4702 0.916831 7.05599 0.916831C6.64177 0.916831 6.30599 1.25262 6.30599 1.66683L7.80599 1.66683ZM10.3893 11.5835L3.72266 11.5835L3.72266 13.0835L10.3893 13.0835L10.3893 11.5835ZM2.47266 10.3335L2.47266 9.66683L0.972656 9.66683L0.972656 10.3335L2.47266 10.3335ZM11.6393 9.66683L11.6393 10.3335L13.1393 10.3335L13.1393 9.66683L11.6393 9.66683ZM10.3893 13.0835C11.9081 13.0835 13.1393 11.8523 13.1393 10.3335L11.6393 10.3335C11.6393 11.0239 11.0797 11.5835 10.3893 11.5835L10.3893 13.0835ZM3.72266 11.5835C3.0323 11.5835 2.47266 11.0239 2.47266 10.3335L0.972656 10.3335C0.972656 11.8523 2.20387 13.0835 3.72266 13.0835L3.72266 11.5835ZM9.19232 6.46983L6.52566 9.1365L7.58632 10.1972L10.253 7.53049L9.19232 6.46983ZM7.58632 9.1365L4.91965 6.46983L3.85899 7.53049L6.52566 10.1972L7.58632 9.1365ZM7.80599 9.66683L7.80599 1.66683L6.30599 1.66683L6.30599 9.66683L7.80599 9.66683Z"
                    fill="white"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
        <div className=" mt-6 bg-white shadow-sm  shadow-slate-400/40 flex  rounded-lg mx-auto justify-center flex-col  md:col-span-2">
          <div id="pdf-content">
            <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0   mx-auto max-w-5xl">
              <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
                Application Overview
              </h2>
              <p className="text-gray-700 font-normal mt-1 text-base">
                Summary of application details and usage{" "}
              </p>

              <div className="mt-8 sm:mt-18">
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Name
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {overview && overview.appName}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Department
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {formData.department?.name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 mt-8 max-w-2xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Owner
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {overview?.appOwner}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Source code link
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {overview?.sourceCodeLink}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1  gap-x-6 gap-y-6 sm:grid-cols-2 mt-8 max-w-2xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Version
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {overview?.releaseVersion}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Version date
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {overview?.releaseDate
                          ? formatDate(new Date(overview.releaseDate))
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-8 max-w-3xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Description{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {overview?.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-8 max-w-3xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Usage{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {overview?.usage}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-8 max-w-3xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Key Features
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {overview?.features.join(", ")}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="grid grid-cols-1 mt-8 max-w-3xl gap-4">
                    <div>
                      <h4 className=" text-sm font-medium leading-6 text-gray-700">
                        End users{" "}
                      </h4>
                      <div className="mt-1">
                        <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                          {overview?.endUsers.join(", ")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-t border-gray-300  max-w-5xl  mt-8"></div>
            </div>
            <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0   mx-auto max-w-5xl">
              <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
                Architecture and Technology
              </h2>
              <p className="text-gray-700 font-normal mt-1 text-base">
                Details on application versioning, management, and maintenance.{" "}
              </p>

              <div className="mt-8 sm:mt-18">
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Type of application{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {architectureAndTechnology?.applicationType}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid mt-8 grid-cols-1 gap-x-6 gap-y-6  max-w-3xl">
                  <div>
                    <h4 className=" text-sm  font-medium leading-6 text-gray-700">
                      Business/ technical architecture{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {architectureAndTechnology?.technicalArchitecture}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-8 max-w-3xl">
                  <div>
                    <h4 className=" text-sm  font-medium leading-6 text-gray-700">
                      Technology stack{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {architectureAndTechnology?.technologyStack.join(", ")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-8 max-w-3xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Feature enhancement Process{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify   text-gray-700 text-sm sm:leading-6 ">
                        {architectureAndTechnology?.featureEnhancement}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6  max-w-3xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Software Tools{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full    text-gray-700 text-sm sm:leading-6 ">
                        {architectureAndTechnology?.softwareTools.join(", ")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-t border-gray-300  max-w-5xl mt-8"></div>
            </div>
            <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0   mx-auto max-w-5xl">
              <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
                Development and Maintenance{" "}
              </h2>
              <p className="text-gray-700 font-normal mt-1 text-base">
                Details on application versioning, management, and maintenance.{" "}
              </p>

              <div className="mt-8 sm:mt-18">
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Managed by
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {developmentAndMaintenance?.managedBy}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Ticket dump{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {developmentAndMaintenance?.ticketDump ? "Yes" : "No"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Name
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {developmentAndMaintenance?.managerName}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Contact number{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {developmentAndMaintenance?.managerContactNumber}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 mt-8 max-w-2xl">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Software Methodologies
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {developmentAndMaintenance?.softwareMethodology}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Stability
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full  text-gray-700 text-sm sm:leading-6 ">
                        {developmentAndMaintenance?.stability ? "Yes" : "No"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Source code management{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify   text-gray-700 text-sm sm:leading-6 ">
                        {developmentAndMaintenance?.sourceCodeManager}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Code stored in{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {developmentAndMaintenance?.codeStorage}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6  max-w-2xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Connectivity planning{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {developmentAndMaintenance?.connectivityPlanning
                          ? developmentAndMaintenance?.connectivityPlanning
                          : "NA"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-6  max-w-2xl mt-8">
                  <div>
                    <h4 className=" text-sm font-medium leading-6 text-gray-700">
                      Release Management and Change Control{" "}
                    </h4>
                    <div className="mt-1">
                      <p className=" w-full text-justify  text-gray-700 text-sm sm:leading-6 ">
                        {developmentAndMaintenance?.releaseAndChange
                          ? developmentAndMaintenance?.releaseAndChange
                          : "NA"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-t border-gray-300  max-w-5xl  mt-8"></div>
            </div>
            <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0   mx-auto max-w-5xl">
              <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
                Team and Support{" "}
              </h2>
              <p className="text-gray-700 font-normal mt-1 text-base">
                Information about the responsible team and support{" "}
              </p>

              <div className="mt-8 sm:mt-18">
                {Array.isArray(teamAndSupport) &&
                  teamAndSupport.map((member: TeamMember, index: number) => (
                    <div
                      key={index}
                      className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 max-w-2xl mt-8"
                    >
                      <div>
                        <h4 className="text-sm font-medium leading-6 text-gray-700">
                          Name
                        </h4>
                        <div className="mt-1">
                          <p className="w-full text-gray-700 text-sm sm:leading-6">
                            {member.name}
                          </p>
                        </div>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium leading-6 text-gray-700">
                          Skills
                        </h4>
                        <div className="mt-1">
                          <p className="w-full text-gray-700 text-sm sm:leading-6">
                            {member.skills.join(", ")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="border border-t border-gray-300  max-w-5xl  mt-8"></div>
            </div>
            <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0   mx-auto max-w-5xl">
              <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
                Documents{" "}
              </h2>
              <p className="text-gray-700 font-normal mt-1 text-base">
                All the documents related to this applcation{" "}
              </p>

              <div className="mt-8 sm:mt-18 flex flex-wrap justify-between gap-4">
                {Array.isArray(documents) &&
                  documents.map(
                    (document: ApplicationDocument, index: number) => (
                      <div
                        key={index}
                        className="flex-0 w-[calc(50%-8px)] mb-4"
                      >
                        <div>
                          <h4 className="text-sm font-medium leading-6 text-gray-700">
                            Document Name
                          </h4>
                          <div
                            onClick={() => {
                              handleDocumentDownload(
                                document.applicationId,
                                document.documentName                              );
                            }}
                            className="mt-1 cursor-pointer"
                          >
                            <p className="w-full font-medium text-gray-700 text-sm sm:leading-6 text-indigo-600">
                              {document?.documentName &&
                                document.documentName.replaceAll("_", " ")}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
              <div className="border border-t border-gray-300  max-w-5xl  mt-8"></div>
            </div>
          </div>
          <div className="flex items-center w-full pb-10 sm:pb-0 justify-end mt-9 mb-8 mx-auto  gap-x-3 max-w-5xl flex-end">
            <button
              type="button"
              onClick={handleEdit}
              className="text-sm w-32 h-10 font-semibold leading-6 bg-indigo-600 text-white"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      {showEditModal && (
        <div className="fixed top-0 left-0 p-10 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-auto">
          <div className="bg-white p-8 shadow-lg w-full max-w-3xl overflow-y-auto custom-scrollbar rounded-md max-h-full">
            <UpdateApplication
              handleClose={handleCloseEditModal}
              formData={formData}
            />
          </div>
        </div>
      )}
        {error && (
        <div className="fixed inset-0 z-10 bg-gray-300 bg-opacity-75 overscroll-y-none">
          <div className="flex justify-center items-center h-full mx-12 overscroll-none ">
            <div className="bg-white w-64 h-64 p-8 rounded-lg shadow-lg overscroll-none flex flex-col items-center">
              <XMarkIcon className="text-red-500 w-20 h-10 stroke-red-500 stroke-[4] font-bold" />
              <h2 className="text-lg font-bold mb-4 mt-4">Error</h2>
              <div className="text-gray-700 mb-5 custom-scrollbar text-center max-h-40 overflow-auto">
                {error && (
                  <div className="text-gray-500  font-normal text-sm  word-break break-all">
                    {error}
                  </div>
                )}
              </div>
              <button
                onClick={() => setError("")}
                className="bg-blue-500 text-white text-center w-40 py-1 rounded-md hover:bg-blue-600"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicationView;
