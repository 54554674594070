import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import { useParams } from "react-router-dom";
import axios from "axios";
import UpdateDepartmentForm from "./UpdateDepartmentForm";
import Breadcrumb from "../Layout/Breadcrumb";
import { GETDEPTID } from "../../lib/api";
import { departmentBreadcrumbs } from "../../types/breadcrumbNavType";
interface Department {
  _id: string;
  name: string;
  applications: string[];
}

interface ApplicationDetials {
  POC: string;
  description: string;
  POCContactNumber: string;
  name: string;
}

const UpdateDepartmentPage: React.FC = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const { id } = useParams();

  const [applicationDetails, setApplicationDetails] =
    useState<ApplicationDetials>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}${GETDEPTID.GETID}/${id}`,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        setApplicationDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 mt-7 pb-20">
        <h2 className="text-2xl font-normal text-black">Add Department</h2>
        <p className="text-sm font-medium text-zinc-500 mt-6">
          <Breadcrumb items={departmentBreadcrumbs} />
        </p>
        <div className=" mt-2.5 bg-white shadow-sm  shadow-slate-400/40 flex  rounded-lg mx-auto justify-center flex-col  md:col-span-2">
          <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0  mx-auto max-w-2xl">
            <h2 className="text-gray-900 font-bold text-lg">
              Department overview
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              Summary of department
            </p>
            {applicationDetails && (
              <UpdateDepartmentForm applicationDetails={applicationDetails} />
            )}{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateDepartmentPage;
