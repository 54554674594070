import React, { createContext, useContext, useReducer, ReactNode } from "react";
import {
  OverviewData,
  ArchTechData,
  DevMaintenanceData,
  TeamSupportData,
} from "../types/aplicationCreateTypes";

interface AppState {
  overview: OverviewData | {};
  architectureAndTechnology: ArchTechData | {};
  developmentAndMaintenance: DevMaintenanceData | {};
  teamAndSupport: TeamSupportData | {};
  isActive: boolean;
  department: string;
  departmentName: string;
}

type Action =
  | { type: "SET_OVERVIEW"; payload: OverviewData }
  | { type: "SET_ARCH_TECH"; payload: ArchTechData }
  | { type: "SET_DEV_MAINTENANCE"; payload: DevMaintenanceData }
  | { type: "SET_TEAM_SUPPORT"; payload: TeamSupportData }
  | { type: "SET_ACTIVE_STATUS"; payload: boolean }
  | { type: "SET_DEPARTMENTID"; payload: string }
  | { type: "SET_DEPARTMENTNAME"; payload: string };

interface AppFormProviderProps {
  children: ReactNode;
}

const initialState: AppState = {
  overview: {},
  architectureAndTechnology: {},
  developmentAndMaintenance: {},
  teamAndSupport: {},
  isActive: false,
  department: "",
  departmentName: "",
};

const AppFormContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

const appReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case "SET_OVERVIEW":
      return { ...state, overview: action.payload };
    case "SET_ARCH_TECH":
      return { ...state, architectureAndTechnology: action.payload };
    case "SET_DEV_MAINTENANCE":
      return { ...state, developmentAndMaintenance: action.payload };
    case "SET_TEAM_SUPPORT":
      return { ...state, teamAndSupport: action.payload.teamAndSupport };
    case "SET_ACTIVE_STATUS":
      return { ...state, isActive: action.payload };
    case "SET_DEPARTMENTID":
      return { ...state, department: action.payload };
    case "SET_DEPARTMENTNAME":
      return { ...state, departmentName: action.payload };

    default:
      return state;
  }
};

export const AppFormProvider: React.FC<AppFormProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppFormContext.Provider value={{ state, dispatch }}>
      {children}
    </AppFormContext.Provider>
  );
};

export const useAppForm = () => useContext(AppFormContext);
