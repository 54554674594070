import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import topLogo from "../../assets/topLogo.png";
import mapImage from "../../assets/mapImage.png";
import bottomImage from "../../assets/bottomLogo.png";
import check from "../../assets/check.png";
import { USER } from "../../lib/api";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export interface ResetPasswordValues {
  email: string;
  newPassword: string;
  confirmPassword: string;
}

const ResetPassword: React.FC = () => {
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [resetError, setRestError] = useState<string>("");

  const toggleNewPasswordVisibility = (): void => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = (): void => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const validationSchema: Yup.Schema<{
    email: string;
    newPassword: string;
    confirmPassword: string;
  }> = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    newPassword: Yup.string()
      .min(8, "New Password must be at least 8 characters")
      .required("New Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords must match")
      .min(8, "Confirm Password must be at least 8 characters")
      .required("Confirm Password is required"),
  });

  const initialValues: ResetPasswordValues = {
    email: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleResetPassword = async (values: ResetPasswordValues) => {
    try {
      await axios.put(`${BACKEND_URL}${USER.RESET}`, values);
      setShowModal(true);
    } catch (error) {
      let errorMessage = "An unexpected error occurred";
      if (axios.isAxiosError(error)) {
        if (error.response) {
          errorMessage =
            error.response.data.message ||
            "An error occurred while resetting the password";
        } else {
          errorMessage = "An error occurred while processing your request";
        }
      }
      setRestError(errorMessage);
    }
  };

  return (
    <div className="min-h-screen flex flex-col lg:flex-row justify-center items-center relative">
      <div
        className={`${
          showModal
            ? "absolute blur-sm bg-opacity-75 bg-gray-300 inset-0 z-10"
            : ""
        }`}
      ></div>
      <div className="w-full lg:w-9/12 h-full px-4 py-8 lg:py-12 sm:px-6 lg:px-20 xl:px-24 flex justify-center items-center relative">
        <div className="w-full max-w-sm">
          <div className="flex flex-col items-center mb-6">
            <img
              className="h-10 w-auto mb-2"
              src={topLogo}
              alt="Your Company"
            />
            <h1 className="underline text-logo pb-6">BBMP REPORT</h1>
            <h3 className="text-xl font-bold leading-8 tracking-tight text-gray-800">
              Reset Password
            </h3>
            <p className="text-sm leading-6 text-gray-500">
              Set the new password for your account.
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleResetPassword}
          >
            {(formik) => (
              <Form className="space-y-6">
                <Field
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Email"
                  className={`block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                    formik.errors.email && formik.touched.email
                      ? "border-red-500"
                      : ""
                  }`}
                />
                <ErrorMessage
                  name="email"
                  component="span"
                  className="text-red-500 text-xs mt-0"
                />

                <div className="mt-0 relative">
                  <Field
                    id="newPassword"
                    name="newPassword"
                    type={newPasswordVisible ? "text" : "password"}
                    autoComplete="new-password"
                    placeholder="New Password"
                    className={`block w-full rounded-md border-0 px-2 py-1.5 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                      formik.errors.newPassword && formik.touched.newPassword
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-3 py-1.5 flex items-center text-gray-400"
                    onClick={toggleNewPasswordVisibility}
                  >
                    {newPasswordVisible ? <FiEyeOff /> : <FiEye />}
                  </button>
                </div>
                <ErrorMessage
                  name="newPassword"
                  component="span"
                  className="text-red-500 text-xs mt-0 "
                />

                <div className="mt-0 relative">
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    type={confirmPasswordVisible ? "text" : "password"}
                    autoComplete="new-password"
                    placeholder="Confirm Password"
                    className={`block w-full rounded-md border-0 px-2 py-1.5 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                      formik.errors.confirmPassword &&
                      formik.touched.confirmPassword
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-3 py-1.5 flex items-center text-gray-400"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {confirmPasswordVisible ? <FiEyeOff /> : <FiEye />}
                  </button>
                </div>
                <ErrorMessage
                  name="confirmPassword"
                  component="span"
                  className="text-red-500 text-xs mt-1"
                />
                {resetError && (
                  <div className="text-red-500 text-xs mt-1">{resetError}</div>
                )}
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={formik.isSubmitting}
                >
                  Reset Password
                </button>

                <p className="text-gray-500 flex justify-center text-sm mt-2 cursor-pointer hover:text-red-500">
                  <Link to="/">Cancel</Link>
                </p>
              </Form>
            )}
          </Formik>
          <div
            className={`${
              showModal ? "fixed" : "hidden"
            } inset-0 z-20 overflow-y-auto`}
          >
            <div className="flex justify-center mt-12 min-h-screen mx-12 overscroll-none">
              <div className="bg-white w-64 h-64 p-8 rounded-lg shadow-lg overscroll-none lex flex-col items-center">
                <img className="w-5 h-5" src={check} alt="" />
                <h2 className="text-lg font-bold mb-4">Congratulations!</h2>
                <p className="text-gray-700 mb-5 text-center  ">
                  Your password has been successfully reset. You can now log in
                  with your new password.
                </p>
                <Link
                  to="/"
                  className="bg-blue-500 text-white text-center w-40  py-1 rounded-md hover:bg-blue-600"
                >
                  Log In
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-3 pt-6">
            <p className="mr-2 px-2">Powered by</p>
            <img className="h-8 w-auto" src={bottomImage} alt="Your Company" />
          </div>
        </div>
      </div>
      <div className="w-full mx-12 gap-x-8 lg:w-7/12 hidden lg:flex justify-center items-center ">
        <img
          className="h-auto max-h-80 mr-40"
          src={mapImage}
          alt="Karnataka Map"
        />
      </div>
    </div>
  );
};

export default ResetPassword;
