interface SessionStorageData {
  name?: string;
  role?: string;
  getName(): string;
  getRole(): string;
  login(responseData: { name: string; role: string }): void;
}

export const SessionStorage: SessionStorageData = {
  getName() {
    const name = sessionStorage.getItem("name");
    return name ? name.trim() : "";
  },

  getRole() {
    const role = sessionStorage.getItem("role");
    return role ? role.trim() : "";
  },

  login(responseData: { name: string; role: string }) {
    sessionStorage.setItem("name", responseData.name);
    sessionStorage.setItem("role", responseData.role);
  },
};
