export const DROPDOWN = {
    endUserOptions : [
        'Bangalore Citizen',
        'Assistant Engineer (AE)',
        'Assistant Executive Engineer',
        'Chief Engineer (CE)',
        'Commissioner',
        'Executive Engineer',
        'Quality Control Engineer',
        'DEO (District Election Officer)',
        'RO (Revenue Officer)',
        'ARO (Assistant Revenue Officer)',
        'DC (Deputy Commissioner)',
        'JC (Joint Commissioner)',
        'ZC (Zonal Commissioner)',
        'SC (Special Commissioner)',
        'BBMP Administrative Staff',
        'BBMP Technical Staff',
        'Drawing and Disbursing Officers (DDOs)',
        'Chief Commissioner',
        'Assistant Controller of Finance (ACF)',
        'Zonal/Joint Commissioners',
        'Medical Officer',
        'City Programme Manager',
        'Student',
        'Range Forest Officer',
        'Assistant Conservator of Forest',
        'Deputy Range Forest Officer',
        'Forest Guard',
        'Contractor',
        'Administrator',
        'Superintending Engineer',
        'Deputy Conservator of Forests',
        'Government Auditor',
        'Deputy Health Officer',
        'Health Inspector',
        'Revenue Officer',
        'Horticulture Inspector',
        'Joint Director of Horticulture',
        'Zonal Horticulture Superintendent',
        'Horticultural Engineer',
        'First Division Assistant',
        'Second Division Assistant',
        'Maintenance Agency',
        'Legal Advisor',
        'Case Worker',
        'Head of the Legal Cell (HLC)',
        'Deputy Law Officer (DLO)',
        'Assistant Law Officer (ALO)',
        'Junior Law Officer (JLO)',
        'Advocate Department',
        'HLC Admin',
        'Revenue Inspector',
        'JHI’s',
        'Supervisor',
        'Ward Marshal'
    ]
    
}

export const DOCUMENTS = 
{
    Business_Requirement: "Business_Requirement", 
    API_Documentation: "API_Documentation", 
    Service_Level_Agreement: "Service_Level_Agreement", 
    Release_Notes: "Release_Notes",
    System_Design:"System_Design",
    Test_Plan:"Test_Plan",
    User_Interface:"User_Interface",
    User_Manuals: "User_Manuals"
}
