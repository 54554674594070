import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../Layout/Header";
import axios from "axios";
import { APPLICATIONDASHBOARD } from "../../lib/api";
import Breadcrumb from "../Layout/Breadcrumb";
import { applicationBreadcrumbs } from "../../types/breadcrumbNavType";
import RagStatus from "../ApplicationView/RagStatus";
interface ApplicationDetials {
  activeCount: number;
  applications: {
    appOwner: string;
    appName: string;
    isActive: boolean;
    _id: string;
    ragStatus: 'Red' | 'Amber' | 'Green';
  }[];
  inactiveCount: string;
  name: string;
}

const ApplicationDashboard: React.FC = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const { id } = useParams();
  const [applicationDetails, setApplicationDetails] =
    useState<ApplicationDetials>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}${APPLICATIONDASHBOARD.DEPARTMENT}/${id}`,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        setApplicationDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 2xl:pr-8 2xl:pl-11">
        <div className="flex justify-between items-center mt-6">
          <div className="">
            <h1 className="text-black text-2xl font-normal">
              {applicationDetails?.name}
            </h1>
            <p className="text-sm font-medium text-zinc-500 mt-4">
              {" "}
              <Breadcrumb items={applicationBreadcrumbs} />
            </p>{" "}
          </div>

          <Link
            to={`/create-application?departmentId=${id}&departmentName=${applicationDetails?.name}`}
          >
            <button
              type="button"
              className="h-10 rounded py-2 px-8   gap-2 flex items-center bg-indigo-500  text-sm font-semibold text-white shadow-sm"
            >
              Create App
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00002 1.6665V12.3332M12.3334 6.99984L1.66669 6.99984"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </Link>
        </div>
        <div className="border-t bg-gray-300 mt-4"></div>
        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8">
          <div className=" shadow-sm rounded-lg shadow-slate-400/40 bg-white py-6 px-4">
            <p className="text-base font-normal text-neutral-600">Total apps</p>
            <h3 className="mt-3 font-medium text-2xl text-neutral-800">
              {applicationDetails?.applications.length}
            </h3>
          </div>

          <div className=" shadow-sm rounded-lg shadow-slate-400/40 bg-white py-6 px-4">
            <p className="text-base font-normal text-neutral-600">
              Active apps
            </p>
            <h3 className="mt-3 font-medium text-2xl text-neutral-800">
              {applicationDetails?.activeCount}
            </h3>
          </div>
        </div>
        <div className="bg-white py-8 px-6 mt-4 mb-12 rounded-lg shadow-sm shadow-black/8">
          <div className="flex flex-col md:flex-row gap-2 md:gap-0">
            <div className="sm:flex-auto">
            <h3 className="text-gray-900 font-bold text-lg">
              Application list
            </h3>
            <p className="text-gray-600 font-normal mt-1 text-base">
              This is a list of application and applications details
            </p>
            </div>
            <div className=" flex flex-col md:flex-row gap-2 border md:m-auto p-3">
              <div className="flex items-center ">
              <RagStatus status="Red" size={4}/> <p className="text-slate-600 text-sm">Critical Condition</p>
              </div>
              <div className="flex items-center ">
              <RagStatus status="Amber" size={4}/> <p className="text-slate-600 text-sm">Potential Problems</p>
              </div>
              <div className="flex items-center ">
              <RagStatus status="Green" size={4}/> <p className="text-slate-600 text-sm">Good Condition</p>
              </div>
            </div>
          </div>

          <div className="mt-6 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 rounded-l-lg text-left text-sm font-semibold uppercase text-gray-900 sm:pl-3"
                      >
                        application
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 uppercase text-left text-sm font-semibold text-gray-900"
                      >
                        Point Of Contact
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 uppercase text-left text-sm font-semibold text-gray-900"
                      >
                        STATUS
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 uppercase text-left text-sm font-semibold text-gray-900"
                      >
                        RAG Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 uppercase rounded-r-lg text-left text-sm font-semibold text-gray-900"
                      >
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {applicationDetails?.applications.map(
                      (application, index: number) => (
                        <tr key={index} className="even:bg-gray-50">
                          <td className="whitespace-nowrap rounded-l-lg py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                            {application?.appName}
                          </td>
                          <td className="whitespace-nowrap px-3 font-medium py-4 text-sm text-gray-900">
                            {application?.appOwner}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {application?.isActive ? (
                              <div className="rounded-md py-0.5 pr-3 pl-1 max-w-20 text-gray-900   text-sm font-semibold ">
                                Active
                              </div>
                            ) : (
                              <div className="rounded-md py-0.5 pr-3 pl-1 max-w-20 text-gray-900   font-semibold  text-sm ">
                                Inactive
                              </div>
                            )}
                          </td>
                          <td className=" py-4 text-sm text-gray-500">
                          {application.ragStatus && <div className="ml-5 md:ml-10"><RagStatus status={application.ragStatus} size={3}/></div> }
                          </td>

                          <td className="whitespace-nowrap rounded-r-lg px-3 py-4 text-sm text-gray-500">
                            <Link
                              to={`/view-application/${application._id}`}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              View Report
                            </Link>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationDashboard;

