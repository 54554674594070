interface FileInputProps {
    id: string;
    label: string;
    onChooseFileClick: () => void;
    uploadedFileName: string | null;
  }
  
  export const FileInput: React.FC<FileInputProps> = ({ id, label, onChooseFileClick, uploadedFileName }) => (
    <div className="sm:col-span-2">
      <label className="block text-sm font-medium leading-6 text-gray-700">
        {label}
      </label>
      <div className="mt-1 flex items-center border rounded-md">
        <button
          className="text-sm h-9 text-gray-600 bg-white border-gray-300 px-4 py-2 rounded-l-md cursor-pointer"
          aria-describedby={`${id}_help`}
          onClick={onChooseFileClick}
        >
          Upload File
        </button>
        <div
          className={`flex-grow px-4 py-2 text-sm bg-gray-100 rounded-r-md ${
            uploadedFileName ? "text-indigo-600" : "text-slate-500"
          }`}
        >
          {uploadedFileName || "No file uploaded"}
        </div>
      </div>
    </div>
  );