import React from "react";

interface StepsProps {
  steps: { id: string; name: string; status?: string }[];
}

const FormSteps: React.FC<StepsProps> = ({ steps }) => {
  return (
    <div className="max-w-xl mx-auto w-full mt-12 md:max-w-6xl">
      <nav aria-label="Progress">
        <ol
          role="list"
          className="divide-y divide-gray-300 h-auto md:h-12 rounded-md border border-gray-300 md:flex md:divide-y-0"
        >
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative  md:flex md:flex-1">
              {step.status === "complete" ? (
                <div className="group  flex w-full items-center">
                  <span className="flex items-center  px-4 md:px-5 py-3 text-sm font-medium">
                    <span className="flex h-7 w-7  flex-shrink-0 items-center justify-center text-white rounded-full bg-indigo-600 ">
                      {step.id}
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500">
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : step.status === "current" ? (
                <div
                  className="flex items-center px-4 md:px-6 py-4 text-sm font-medium"
                  aria-current="step"
                >
                  <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                    <span className="text-indigo-600">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-indigo-600">
                    {step.name}
                  </span>
                </div>
              ) : (
                <div className="group flex items-center">
                  <span className="flex items-center px-4 md:px-6 py-4 text-sm font-medium">
                    <span className="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 ">
                      <span className="text-gray-500 group-hover:text-gray-900">
                        {step.id}
                      </span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 ">
                      {step.name}
                    </span>
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 && (
                <>
                  <div
                    className="absolute right-0 top-0 hidden h-full w-2 md:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default FormSteps;
