import LogIn from "../pages/LogIn/LogIn";
import { Navigate, Route, Routes } from "react-router-dom";
import ResetPassword from "../pages/LogIn/ResetPassword";
import AddApplication from "../pages/Application/AddApplication";
import { AppFormProvider } from "../context/AppFormContext";
import ApplicationView from "../components/ApplicationView/ApplicationView";
import ApplicationDashboard from "../components/ApplicationDashboard/ApplicationDashboard";
import DepartmentPage from "../components/AddDepartment/DepartmentPage";
import DashBoardPage from "../pages/Department/DashBoardPage";
import UpdateDepartmentPage from "../components/UpdateDepartment/UpdateDepartmentPage";
import DepartmentSearchResult from "../components/DepartmentSearchResult/DepartmentSearchResult";
import DocumentUpload from "../components/DocumentUpload/DocumentUpload";


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/login" element={<LogIn />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/dashboard" element={<DashBoardPage />} />
      <Route path="/create-department" element={<DepartmentPage />} />
      <Route path="/view-application/:id" element={<ApplicationView />} />
      <Route path="/document-upload/:id" element={<DocumentUpload />} />

      <Route
        path="/create-application"
        element={
          <AppFormProvider>
            <AddApplication />
          </AppFormProvider>
        }
      />
      <Route path="/add-application" element={<AddApplication />} />
      <Route
        path="/adding-application/:id"
        element={<ApplicationDashboard />}
      />
      <Route path="/update-department/:id" element={<UpdateDepartmentPage />} />
      <Route
        path="/updateSearch-department/:id"
        element={<DepartmentSearchResult />}
      />
    </Routes>
  );
};

export default AppRoutes;
