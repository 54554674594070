import React, { useEffect, useState, useRef } from "react";
import { Switch } from "@headlessui/react";
import Header from "../Layout/Header";
import { useFormik } from "formik";
import * as Yup from "yup";
import { OverviewProps } from "../../types/stageComponentTypes";
import { OverviewData } from "../../types/aplicationCreateTypes";
import { useAppForm } from "../../context/AppFormContext";
import FormSteps from "../FormStage/FormStage";
import Breadcrumb from "../Layout/Breadcrumb";
import { overviewSteps } from "../../types/formStepsPropsTypes";
import { applicationBreadcrumbs } from "../../types/breadcrumbNavType";
import { DROPDOWN } from "../../constants/constants";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const validationSchema = Yup.object().shape({
  appName: Yup.string()
    .required("Name is required")
    .matches(/^[a-zA-Z\s\W]+$/, "Name can only contain alphabets"),
  appOwner: Yup.string()
    .required("Name is required")
    .matches(/^[a-zA-Z\s\W]+$/, "Name can only contain alphabets"),
});

const ApplicationOverview: React.FC<OverviewProps> = ({ onNext, onPrev }) => {
  const { state, dispatch } = useAppForm();

  const [enabled, setEnabled] = useState<boolean>(true);
  useEffect(() => {
    dispatch({ type: "SET_ACTIVE_STATUS", payload: enabled });
  }, [enabled, dispatch]);

  const initialOptions: string[] = DROPDOWN.endUserOptions;

  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    Array.from(new Set((state.overview as OverviewData)?.endUsers || []))
  );

  const [options, setOptions] = useState<string[]>(initialOptions);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchTerm) {
      const filteredOptions = initialOptions.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setOptions(filteredOptions);
    } else {
      setOptions(initialOptions);
    }
  }, [searchTerm, initialOptions]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectOption = (option: string) => {
    if (!selectedUsers.includes(option)) {
      const newSelectedUsers = [...selectedUsers, option];
      const uniqueSelectedUsers = Array.from(new Set(newSelectedUsers));
      setSelectedUsers(uniqueSelectedUsers);
      formik.setFieldValue("endUsers", uniqueSelectedUsers);
    }
    setSearchTerm("");
    setIsDropdownVisible(false);
  };

  const handleRemoveOption = (
    opt: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const newSelectedUsers = selectedUsers.filter((option) => option !== opt);
    setSelectedUsers(newSelectedUsers);
    formik.setFieldValue("endUsers", newSelectedUsers);
  };
  const formik = useFormik<OverviewData>({
    initialValues: {
      appName: (state.overview as OverviewData)?.appName || "",
      link: (state.overview as OverviewData)?.link || "",
      appOwner: (state.overview as OverviewData)?.appOwner || "",
      releaseVersion: (state.overview as OverviewData)?.releaseVersion || "",
      releaseDate: (state.overview as OverviewData)?.releaseDate || "",
      description: (state.overview as OverviewData)?.description || "",
      sourceCodeLink: (state.overview as OverviewData)?.sourceCodeLink || "",
      usage: (state.overview as OverviewData)?.usage || "",
      features: (state.overview as OverviewData)?.features || [],
      endUsers: (state.overview as OverviewData)?.endUsers || [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch({ type: "SET_OVERVIEW", payload: values });
      onNext();
    },
  });

  const handleToggle = () => {
    setEnabled(!enabled);
  };

  return (
    <>
      <Header />

      <div className="px-4 mx-auto max-w-[90rem] sm:px-6 lg:px-8 xl:px-10 mt-5 pb-20">
        <h2 className="text-2xl font-normal text-black mt-6">
          Add Application
        </h2>
        <p className="text-sm font-medium text-zinc-500 mt-4">
          {" "}
          <Breadcrumb items={applicationBreadcrumbs} />
        </p>
        <div className=" mt-6 bg-white shadow-sm  shadow-slate-400/40 flex  rounded-lg mx-auto justify-center flex-col  md:col-span-2">
          <FormSteps steps={overviewSteps} />
          <div className=" mt-12 w-full justify-center flex flex-col p-5 md:p-0   mx-auto max-w-2xl">
            <h2 className="text-gray-900 font-bold text-lg tracking-tight sm:text-xl">
              Application Overview
            </h2>
            <p className="text-gray-600 font-normal mt-1 text-base">
              Summary of application details and usage{" "}
            </p>

            <div className="mt-9 flex items-center">
              <span className="mr-2">Application Status :</span>
              <Switch
                checked={enabled}
                onChange={handleToggle}
                className={classNames(
                  enabled ? "bg-indigo-600" : "bg-gray-200",
                  "relative inline-flex h-5 w-11  flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out "
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-6" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4  transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <span className="ml-2">{enabled ? "Active" : "InActive"}</span>
            </div>

            <form
              onSubmit={formik.handleSubmit}
              className="mt-8 max-w-2xl sm:mt-18"
            >
              <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="appName"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Name<span className="text-red-500"> *</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="appName"
                      name="appName"
                      value={formik.values.appName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Name"
                      className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                    {formik.touched.appName && formik.errors.appName ? (
                      <div className="absolute text-red-500 text-sm mt-1">
                        {formik.errors.appName}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="link"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Link
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="link"
                      name="link"
                      value={formik.values.link}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Application URL"
                      className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                    {formik.touched.link && formik.errors.link ? (
                      <div className="absolute text-red-500 text-sm mt-1">
                        {formik.errors.link}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 mt-8 gap-x-6 gap-y-9 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="appOwner"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Owner<span className="text-red-500"> *</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="appOwner"
                      name="appOwner"
                      value={formik.values.appOwner}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Name of the application appOwner"
                      className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                    {formik.touched.appOwner && formik.errors.appOwner ? (
                      <div className="absolute text-red-500 text-sm mt-1">
                        {formik.errors.appOwner}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div className="grid grid-cols-1  gap-x-6 gap-y-9 sm:grid-cols-2">
                    <div>
                      <label
                        htmlFor="releaseVersion"
                        className="block text-sm font-medium leading-6 text-gray-700"
                      >
                        Version
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="releaseVersion"
                          name="releaseVersion"
                          value={formik.values.releaseVersion}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Release releaseVersion"
                          className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="releaseDate"
                        className="block text-sm font-medium leading-6 text-gray-700"
                      >
                        Version Date
                      </label>
                      <div className="mt-1">
                        <input
                          type="date"
                          id="releaseDate"
                          name="releaseDate"
                          value={
                            formik.values.releaseDate instanceof Date
                              ? formik.values.releaseDate
                                  .toISOString()
                                  .split("T")[0]
                              : formik.values.releaseDate
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Release Date"
                          className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Description
                  </label>

                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxLength={2000}
                      placeholder="Brief description of application"
                      className="block w-full  h-24  rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="usage"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Usage
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="usage"
                      name="usage"
                      value={formik.values.usage}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxLength={2000}
                      placeholder="Application usage"
                      className="block w-full h-24  rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 mt-8 mb-8 gap-x-6 gap-y-6 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="features"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Key Features
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="features"
                      name="features"
                      value={formik.values.features}
                      onChange={(e) => {
                        const newValue = e.target.value.split(",");
                        formik.setFieldValue("features", newValue);
                      }}
                      onBlur={formik.handleBlur}
                      placeholder="key features of the application"
                      className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="sourceCodeLink"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Source Code Link
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="sourceCodeLink"
                      name="sourceCodeLink"
                      value={formik.values.sourceCodeLink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Source code link"
                      className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="endUsers"
                  className="block text-sm font-medium leading-6 text-gray-700"
                >
                  End Users
                </label>
                <div ref={wrapperRef} className="mt-1">
                  <div className="flex flex-wrap gap-2">
                    {selectedUsers.map((option, index) => (
                      <span
                        key={index}
                        className=" text-[#4B79EF] rounded px-2 py-1 flex items-center text-sm bg-[#E5EAFF]"
                      >
                        {option}
                        <button
                          onClick={(e) => handleRemoveOption(option, e)}
                          className="text-lg color text-[#4B79EF] ml-2"
                        >
                          ×
                        </button>
                      </span>
                    ))}
                  </div>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClick={() => setIsDropdownVisible(true)}
                    className="block w-full rounded-md py-2 px-3 text-gray-900 shadow-sm mt-4 placeholder:text-gray-400 placeholder:text-sm placeholder:font-normal border border-gray-300 bg-white sm:text-sm sm:leading-6 outline-none"
                    placeholder="Search..."
                  />
                  {isDropdownVisible && (
                    <ul className="border border-gray-300 max-h-40 overflow-auto">
                      {options.map((option, index) => (
                        <li
                          key={index}
                          className="p-2 hover:bg-gray-200 cursor-pointer text-sm"
                          onClick={() => {
                            handleSelectOption(option);
                          }}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="flex items-center pb-10 sm:pb-0 justify-end mt-9 mb-8 gap-x-3 flex-end">
                <button
                  type="button"
                  className="text-sm w-32 h-10 font-semibold leading-6 text-gray-900"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="rounded-md w-32 h-10 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm"
                >
                  Next
                </button>
              </div>
            </form>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default ApplicationOverview;
